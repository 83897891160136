<template>
  <Dialog
    :visible="props.visible"
    class="dialog"
    :header="false"
    :closable="false"
  >
    <div
      class="wrapper"
    >
    <div
      class="close-wrapper"
    >
      <button
        class="close"
        :onclick="close"
      >
        <img
          :src="CloseGreyIcon"
          alt="Close menu"
        />
      </button>
    </div>
    <div
      class="title"
    >
      Edit session title
    </div>
    <div
      class="toast"
      v-if="showToast"
    >
      <div
        class="toast-bar"
      />
      <img
        :src="InfoAlertIcon"
        alt="Alert"
        :style="{width: '2.4rem', height: '2.4rem', margin: '0'}"
      />
      <div
        class="toast-text"
      >
        Oops! Something went wrong. Please try again later.
      </div>
      <button
        class="toast-icon"
        :onclick="setShowToast(false)"
      >
        <img
          :src="CloseIcon"
          alt="Close menu"
        />
      </button>
    </div>
    <div
    class="input-wrapper"
    >
    <span class="p-input-icon-right">
      <InputText
        id="newTitle"
        v-model="newTitle"
        class="input"
        @keydown="filterKey"
        @focus="showClear = true"
        @blur="delayRemove"
      />
      <i class="pi">
        <div
          class="clear-icon-wrapper"
        >
          <button
            class="clear-icon"
            :onclick="clearText"
            v-if="showClear"            
          >
            <img
              :src="CloseCircleIcon"
              alt="Clear text"
            />

          </button>
        </div>
      </i>
    </span>
    </div>
      <div
        class="buttons"
        :style="{flexDirection: 'row', marginBottom: '4rem'}"
      >
      <button
        @click="close"
        class="buttons-button buttons-cancel"
      >
        Cancel
      </button>
      <button
        @click="changeTitle"
        class="buttons-button buttons-save"
      >
        Save
      </button>
      </div> 
    </div>
  </Dialog>
</template>

<script setup>
import {
  ref,
  onUpdated,
} from "vue";
import CloseGreyIcon from "../assets/images/Close-grey.svg";
import CloseCircleIcon from "../assets/images/CloseCircle.svg";
import CloseIcon from "../assets/images/Close.svg";
import InfoAlertIcon from "../assets/images/InfoAlertRed.svg";

const props = defineProps({
  sessionTitle: {
    type: String,
    required: true,
  },
  sessionId: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  setVisible: {
    type: Function,
    required: true,
  }
});

const emit = defineEmits(['updateSessionList'])

const newTitle = ref(props.sessionTitle)
const loading = ref(false)
const showClear = ref(false)
const showToast = ref(false)

function filterKey(event) {
    let currentStr = event.target.value;
    let key = event.keyCode || event.charCode;
    // Ignore backspace (8) and delete (46)
      //        left (37) and right (39)
      if (
        currentStr.length > 50 &&
        key != 8 &&
        key != 46 &&
        key != 37 &&
        key != 39
      ) {
      event.preventDefault();
    }
}

async function changeTitle() {
  newTitle.value = newTitle.value.trim();
  if (newTitle.value === props.sessionTitle || newTitle.value === "" ) {
    props.setVisible(false);
    return;
  }

  loading.value = true;

  let myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    title: newTitle.value,
  });

  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      process.env.VUE_APP_MP_SERVER_API_URL + `self/sessions/${props.sessionId}`,
      requestOptions
    );
    const data = await response.json();
    loading.value = false;

    if (data.status === "success") {
      emit('updateSessionList', newTitle.value);
      props.setVisible(false);
    } else {
      showToast.value = true;
    }
  } catch (error) {
    console.log(error);
    showToast.value = true;
  }
}

function close() {
  props.setVisible(false);
}

function clearText() {
  newTitle.value = "";
}

function delayRemove() {
  // TODO: come up with something better
  setTimeout( () => showClear.value = false, 250)
}

function setShowToast(visible) {
  showToast.value = visible;
}

onUpdated (() => {
  newTitle.value = props.sessionTitle;
})
</script>

<style lang="scss">
.dialog {
  .p-dialog-header {
    display: none;
  }
  
  .p-dialog-content {
    background-color: map-get($colours, accent-purple-2);
    width: 46rem;
    border-radius: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 46rem;
    margin: 0;
    padding: 0;
    background-color: map-get($colours, accent-purple-2);
    font-family: $font-secondary;

    .close-wrapper {
      display: flex;
      flex-direction: row-reverse;
      width: 44rem;
      margin: 0;

      .close {
        background-color: map-get($colours, accent-purple-2);
        margin-top: 1.6rem;
        margin-right: 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 2.4rem;
          height: 2.4rem;
          margin: 0;
        }
      }
    }

    .title {
      font-family: $font-accent;
      margin: 0;
      padding: 0;
      margin-bottom: 2.4rem;
    }
    .input-wrapper {
      width: 100%;
      margin: 0;
      padding: 0;
      margin-bottom: 4rem;

      .input {
        width: 38.8rem;
        padding: 1rem 3.2rem 1rem 1.2rem;
        color: white;
        background-color: map-get($colours, accent-purple-1);
        &:focus {
          border: solid 0.1rem #d1d1d1;

        }

        &:hover {
          border: solid 0.1rem #d1d1d1;
        }
      }
      .clear-icon {
        border-radius: 50%;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 4rem;
      margin-top: 2rem;
      .buttons-button {
        width: 16rem;
        height: 5rem;
        margin: 0 2.4rem;
        border-radius: 0.8rem;
      }
      .buttons-save {
        color: white;
        background-color: map-get($colours, accent-purple);
      }
      .buttons-cancel {
        color: #474747;
        background-color: #d1d1d1;
      }
    }
  }
}
.clear-icon-wrapper {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  .clear-icon {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
    margin-top: 0.5rem;
    img {
      margin: 0;
      width: 1.6rem;
      height: 1.6rem;
      margin-top: 1.2rem;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.toast {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
  height: 5.6rem;
  border-radius: 0.8rem;
  width: 38.8rem;
  background-color: #f5dee2;
  padding: 0;
  .toast-bar {
    background-color: #b00020;
    width: 1rem;
    height: 100%;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }
  .toast-text {
    font-size: 1.4rem;
    text-overflow: hidden;
    width: 27.2rem;
    color: #b00020;
    text-align: justify;
  }
  .toast-icon {
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    margin: 0;
    margin-right: 1.2rem;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  img {
    margin: 0;
    width: 2.4rem;
    height: 2.4rem;
  }
}
</style>
