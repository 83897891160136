<template>
  <button
    :class="[
      'underlined-button',
      light && 'light',
      mediumLight && 'medium-light',
      accent && 'accent',
      big && 'big',
      small && 'small',
      noUnderline && 'no-underline',
      medium && 'medium',
    ]"
    :disabled="disabled"
    @click="onClick"
    :type="type"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    onClick: Function,
    text: String,
    type: String,
    light: Boolean,
    mediumLight: Boolean,
    accent: Boolean,
    big: Boolean,
    small: Boolean,
    noUnderline: Boolean,
    medium: Boolean,
  },
};
</script>

<style lang="scss">
.underlined-button {
  text-decoration: underline;
  font-size: 1.4rem;
  color: map-get($component-colours, underlined-button-font-colour-dark);
  font-family: $font-secondary;
  background: none;
  padding: 0;
  letter-spacing: 0.01rem;

  &:hover {
    color: lighten(
      map-get($component-colours, underlined-button-font-colour-dark),
      30%
    );
  }

  &.light {
    color: map-get($component-colours, underlined-button-font-colour-light);
  }

  &.medium-light {
    color: map-get($colours, grey-3);
  }

  &.accent {
    color: map-get($component-colours, underlined-button-font-colour-accent);
  }

  &.big {
    font-size: 1.6rem;
  }

  &.small {
    font-size: 1.2rem;
  }

  &:disabled {
    color: map-get($colours, grey-3);
  }

  &.no-underline {
    text-decoration: none;
  }
  &.medium {
    font-weight: 500;
  }
}
</style>