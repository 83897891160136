<template>
  <div class="order-summary-card">
    <div class="title">{{ title }}</div>
    <div class="duration">{{ durationHMS }}</div>
    <ul class="features">
      <li v-for="feature of features" :key="feature">
        {{ feature }}
      </li>
    </ul>
    <div class="cost label-value-row">
      <span class="label">Cost</span>
      <div class="value">
        <span> ${{ costRate }} per hour x {{ durationHMS }}</span>
        <span> {{}} </span>
      </div>
    </div>
    <div class="subtotal label-value-row">
      <span class="label">Subtotal</span>
      <span class="value"> {{ formattedOrderPrice }}</span>
    </div>
  </div>
</template>

<script>
import { DEFAULT_COUNTRY_ISO } from "../../assets/constants";

export default {
  props: {
    title: String,
    durationHMS: String,
    durationMilliseconds: String,
    costRate: String,
    isOrderPriceBelowStripeMinimum: Boolean,
    formattedOrderPrice: String,
  },

  computed: {
    currentUserCountryISO() {
      return this.$store.state.currentUser.location || DEFAULT_COUNTRY_ISO;
    },
  },

  data() {
    return {
      features: ["Summary Notes", "Delivered within 48 hours"],
    };
  },
};
</script>

<style lang="scss">
.order-summary-card {
  font-size: 1.4rem;
  font-family: $font-secondary;
  background-color: map-get($colours, accent-purple-3);
  border-radius: 1.4rem;
  padding: 1.6rem;
  margin-top: 1.6rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  text-align: left;

  .title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .duration {
    color: map-get($colours, translucent-white-60);
    font-size: 1.2rem;
  }

  .features {
    color: map-get($colours, translucent-white-60);
    padding-left: 2rem;
    margin: 2.4rem 0 2rem;

    li {
      padding-left: 0.2rem;
    }

    > *:not(:first-child) {
      margin-top: 1.2rem;
    }
  }

  .label-value-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0.1rem solid map-get($colours, translucent-black);

    .label {
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &.cost {
      padding: 2rem 0;
    }

    &.subtotal {
      font-weight: bold;
      padding: 2rem 0 0.4rem;
    }
  }
}
</style>
