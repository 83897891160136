<template>
  <div
    class="info-panel panel width-transition"
    :class="[
      !isOpen ? 'collapsed' : '',
      sessionBrowserPage ? 'session-browser-page' : '',
      notetakingBrowserPage ? 'notetaking-browser-page' : '',
      captioningBrowserPage ? 'captioning-browser-page' : '',
    ]"
  >
    <div class="button-wrapper" :class="!isOpen ? 'collapsed' : ''">
      <!-- TODO: fix all this weird conditional structuring -->
      <IconButton
        v-if="!sessionBrowserPage && (!bookmarkList || !isOpen)"
        :icon="SettingsFilledWhite"
        alt="Settings"
        v-tooltip.right="'Settings'"
        @click="openSettings"
        xsmall
      />
      <IconButton
        v-if="!isOpen && pageTitle"
        :icon="SessionBrowserWhiteIcon"
        alt="Home"
        v-tooltip.right="'Home'"
        @click="goToSessionBrowser"
        class="go-to-session-browser-button"
        xsmall
      />
      <IconButton
        :icon="ChevronLeftWhiteIcon"
        :alt="isOpen ? 'Close panel' : 'Open panel'"
        :rotated="!isOpen"
        class="collapse-button"
        :onclick="toggleInfoPanelOpen"
        v-tooltip.right="'Toggle info panel open'"
        xsmall
      />
    </div>
    <!-- TODO Contradicting classes -->
    <div
      class="expanded"
      :class="[
        !isOpen ? 'hide' : '',
        sessionBrowserPage || notetakingBrowserPage || captioningBrowserPage
          ? 'show-actions'
          : '',
      ]"
    >
      <div class="top-section">
        <header>
          <LogoHeader class="logo-header" />
          <h1>
            <IconButton
              v-if="pageTitle"
              :icon="SessionBrowserWhiteIcon"
              alt="Home"
              v-tooltip.right="'Home'"
              @click="goToSessionBrowser"
              class="go-to-session-browser-button"
              xsmall
            />
          </h1>
          <h1>
            <div
              class="title-editable"
              @click="setShowTitleChangeModal(true)"
              :style="editTitleData && { cursor: 'pointer' }"
            >
              {{ pageTitle }}
              <img v-if="editTitleData" :src="EditIcon" alt="Edit" />
            </div>
          </h1>
        </header>
        <div
          class="actions-wrapper"
          :class="{ collapsed: isOpen }"
          v-if="sessionBrowserPage"
        >
          <div
            v-if="
              sessionBrowserPage ||
              notetakingBrowserPage ||
              captioningBrowserPage
            "
          >
            <RoundedButton
              v-if="isOpen && sessionBrowserPage"
              class="new-menu-button"
              reverse
              text="NEW"
              :image="PlusBlackIcon"
              medium
              mediumwide
              taller
              shadow
              uppercase
              largerFont
              moreRound
              @click="openNewSessionModal(true)"
              aria-haspopup="true"
              aria-controls="new_menu"
            />
          </div>
          <div
            :class="{
              collapsed: !isOpen,
              hide:
                (isOpen && notetakingBrowserPage) ||
                (isOpen && captioningBrowserPage),
            }"
            class="nav-buttons-wrapper"
          >
            <button
              v-if="!isOpen"
              v-tooltip.right="'New'"
              class="nav-button-new"
              @click="openNewSessionModal(true)"
            >
              <img :src="PlusBlackIcon" aria-hidden="true" />
              <span>New</span>
            </button>
            <button
              :class="{
                active: this.$router.currentRoute.value.name === 'Standby',
              }"
              v-tooltip.right="'Home'"
              :onclick="goToHome"
            >
              <img :src="BulletListWhiteIcon" aria-hidden="true" />
              <span>Home</span>
            </button>
            <button
              :class="{
                active: this.$router.currentRoute.value.name === 'Settings',
              }"
              :onclick="openSettings"
              v-tooltip.right="'Settings'"
            >
              <img :src="SettingsFilledWhite" aria-hidden="true" />
              <span>Settings</span>
            </button>
          </div>
        </div>
        <TabView class="tabview" v-if="isOpen && bookmarkList">
          <TabPanel header="Info">
            <div class="info-blocks">
              <div
                v-for="item in data"
                :key="item.title"
                class="info-block"
                v-tooltip.bottom="item.tooltip"
              >
                <h3>{{ item.title }}</h3>
                <template v-for="line in item.info" :key="line.text">
                  <p :class="line.type === 'secondary' ? 'secondary-info' : ''">
                    {{ line.text }}
                  </p>
                </template>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Bookmarks">
            <div class="bookmark-blocks">
              <div
                v-for="bookmark in bookmarkList"
                :key="bookmark.id"
                class="bookmark-block"
              >
                <div
                  class="bookmark-block-timestamp"
                  :class="
                    getTimestamp(bookmark.paragraphIndex) >= 3600
                      ? 'bookmark-block-timestamp-hours'
                      : 'bookmark-block-timestamp-mins'
                  "
                >
                  {{ bookmarkTimestampFormat(bookmark.paragraphIndex) }}
                </div>
                <div
                  class="bookmark-block-text"
                  :style="{
                    borderLeft: `0.2rem solid ${bookmark.color}`,
                    cursor: 'pointer',
                  }"
                  @click="jumpToTimestamp(bookmark.paragraphIndex)"
                >
                  {{ bookmark.text }}
                </div>
                <div class="bookmark-block-bottom">
                  <div>
                    {{ formatDate(bookmark.updatedAt) }}
                  </div>
                  <IconButton
                    :icon="ThreeDotsWhiteIcon"
                    :alt="'Settings'"
                    v-tooltip.top="'Settings'"
                    xsmall
                    @click="(e) => toggle(e, bookmark)"
                  />
                  <Menu
                    class="table-menu-popup-panel"
                    ref="menu2"
                    id="actions_menu"
                    :model="menuProps"
                    :popup="true"
                    :style="{ width: '22.4rem', height: '17.6rem' }"
                  >
                    <template #item="item">
                      <div v-if="item.item.colors" class="color-buttons">
                        <div v-for="color in item.item.colors" :key="color">
                          <button
                            class="color-button"
                            :style="{
                              backgroundColor: color,
                            }"
                            @click="changeBookmarkColor(color)"
                          />
                        </div>
                      </div>

                      <div
                        v-if="!item.item.colors"
                        class="table-menu-popup-panel-buttons"
                      >
                        <button
                          class="table-menu-popup-panel-button"
                          @click="item.item.command"
                        >
                          {{ item.item.label }}
                          <img :src="item.item.icon" alt="icon" />
                        </button>
                      </div>
                    </template>
                  </Menu>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <div v-if="!bookmarkList">
          <div
            v-if="notetakingBrowserPage || captioningBrowserPage"
            class="info-blocks"
            :class="!isOpen ? 'hide' : ''"
          >
            <div
              v-for="item in data"
              :key="item.title"
              class="info-block"
              v-tooltip.bottom="item.tooltip"
            >
              <h3>{{ item.title }}</h3>
              <template v-for="line in item.info" :key="line.text">
                <p :class="line.type === 'secondary' ? 'secondary-info' : ''">
                  {{ line.text }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-section" v-if="!bookmarkList">
        <div v-if="closeDownloadCard" class="download-card">
          <div class="download-card-text">
            <h2>Messenger Pigeon</h2>
            <h3>Free Download</h3>
          </div>
          <button
            class="download-icon-button"
            id="downloadMenuButton"
            @click="toggleDownloadMenu = !toggleDownloadMenu"
          >
            <img :src="DownloadWhiteIcon" />
          </button>
          <button
            class="close-download-card"
            @click="closeDownloadCard = false"
          >
            <img :src="CloseGrey" />
          </button>
          <div
            class="download-popup-wrapper"
            v-click-outside="doStuff"
            v-if="toggleDownloadMenu"
          >
            <div class="large-screen-menu platform-options-menu-wrapper">
              <div class="platform-options-menu menu">
                <div class="mobile-platform platform-option option">
                  Mobile
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="mobile-options-menu platform-option-menu menu">
                    <a
                      :href="LINKS.MP_DOWNLOAD_APPSTORE"
                      class="platform-option option"
                      target="_blank"
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      App Store
                    </a>
                    <a
                      :href="LINKS.MP_DOWNLOAD_PLAYSTORE"
                      class="platform-option option"
                      target="_blank"
                    >
                      <img
                        :src="GooglePlayLogoWhite"
                        class="platform-option-logo"
                      />Google Play
                    </a>
                  </div>
                </div>
                <div class="desktop-platform platform-option option">
                  Desktop
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="desktop-options-menu platform-option-menu menu">
                    <a
                      :href="macOSLink"
                      class="platform-option option"
                      download
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      Mac
                    </a>
                    <a
                      :href="windowsLink"
                      class="platform-option option"
                      download
                    >
                      <img
                        :src="WindowsLogoWhite"
                        class="platform-option-logo"
                      />
                      Windows
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="small-screen-menu platform-options-menu-wrapper">
              <div class="platform-options-menu menu">
                <div class="mobile-platform platform-option option">
                  Mobile
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="mobile-options-menu platform-option-menu menu">
                    <a
                      :href="LINKS.MP_DOWNLOAD_APPSTORE"
                      class="platform-option option"
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      App Store
                    </a>
                    <a
                      :href="LINKS.MP_DOWNLOAD_PLAYSTORE"
                      class="platform-option option"
                    >
                      <img
                        :src="GooglePlayLogoWhite"
                        class="platform-option-logo"
                      />Google Play
                    </a>
                  </div>
                </div>
                <div class="desktop-platform platform-option option">
                  Desktop
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="desktop-options-menu platform-option-menu menu">
                    <a
                      :href="macOSLink"
                      class="platform-option option"
                      download
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      Mac
                    </a>
                    <a
                      :href="windowsLink"
                      class="platform-option option"
                      download
                    >
                      <img
                        :src="WindowsLogoWhite"
                        class="platform-option-logo"
                      />
                      Windows
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-info-wrapper">
          <div class="user-info-actions">
            <button class="bottom-button" @click="logout">
              <img :src="LeaveWhiteIcon" />Sign out
            </button>
          </div>
          <div class="name-wrapper">
            <img :src="ProfileWhiteIcon" />
            <div class="user-full-name">
              {{ this.$store.state.currentUser.name.fullName }}
            </div>
          </div>
          <div class="user-email">
            {{ this.$store.state.currentUser.email }}
          </div>
          <div class="user-type">
            {{ this.$store.state.currentUser.role }}
          </div>
        </div>
        <div v-if="exitButtonText" class="exit-button-wrapper">
          <span v-if="exitButtonHelperText" class="helper-text">{{
            exitButtonHelperText
          }}</span>
          <RoundedButton
            :text="exitButtonText"
            :onClick="exitButtonFunction"
            slim
            wide
            shadow
            class="exit-button"
          />
        </div>
      </div>
      <div class="bottom-section" v-else>
        <IconButton
          v-if="!sessionBrowserPage"
          :icon="SettingsFilledWhite"
          alt="Settings"
          @click="openSettings"
          xsmall
        />
      </div>
    </div>
    <i
      v-if="tooltipInfo"
      class="pi pi-question-circle question-icon"
      :class="!isOpen ? 'hide' : ''"
      v-tooltip.top="{ value: tooltipInfo, escape: true }"
    />
    <Settings
      :isOpen="settingsOpenState"
      :activeTabIndex="settingsActiveTabIndexState"
      :setActiveTabIndex="setSettingsActiveTabIndex"
      :closeSettings="closeSettings"
      accountOption
      subscriptionOption
      feedbackAndHelpOption
      :sttOption="sttOption"
      :captionsLanguageOption="captionsLanguageOption"
      :changeLanguage="changeLanguage"
      :parentLanguage="parentLanguage"
      :changeSttType="changeSttType"
      :selectedSttType="selectedSttType"
      :pagesToShow="settingsPagesToShow"
    />
  </div>
  <TitleChangeModal
    v-if="editTitleData"
    :sessionId="editTitleData?.sessionId"
    :sessionTitle="editTitleData?.sessionTitle"
    :visible="showTitleChangeModal"
    :setVisible="setShowTitleChangeModal"
    @updateSessionList="emitTitleChange"
  />
</template>

<script>
import { appName, LINKS } from "../assets/constants.js";
import RoundedButton from "../components/RoundedButton.vue";
import LogoHeader from "../components/LogoHeader.vue";
import ChevronLeftWhiteIcon from "../assets/images/Chevron-left-white.svg";
import ChevronDownFilledWhite from "../assets/images/Chevron-down-filled-white.svg";
import DownloadWhiteIcon from "../assets/images/Download-white.svg";
import SettingsWhiteIcon from "../assets/images/Settings-white.svg";
import RoundUpdateWhiteIcon from "../assets/images/Round-update-white.svg";
import LeaveWhiteIcon from "../assets/images/Leave-white.svg";
import ProfileWhiteIcon from "../assets/images/Profile-white.svg";
import SessionBrowserWhiteIcon from "../assets/images/Session-browser-white.svg";
import BulletListWhiteIcon from "../assets/images/Bullet-list-white.svg";
import SettingsFilledWhite from "../assets/images/Settings-filled-white.svg";
import PlusBlackIcon from "../assets/images/Plus-black.svg";
import PlusWhiteIcon from "../assets/images/Plus-white.svg";
import MicrophoneDark from "../assets/images/Microphone-Dark.svg";
import UploadDarkIcon from "../assets/images/Upload-Dark.svg";
import IconButton from "../components/IconButton.vue";
import CloseGrey from "../assets/images/Close-grey.svg";
import {
  convertSecondsToHMS,
  convertSecondsToMS,
  logout,
} from "../assets/helpers_legacy";
import Settings from "./Settings/Settings.vue";
import EditIcon from "../assets/images/EditIcon.svg";
import TitleChangeModal from "./TitleChangeModal.vue";
import ThreeDotsWhiteIcon from "../assets/images/ThreeDotsWhiteIcon.svg";
import TrashIcon from "../assets/images/Trash-white.svg";
import CopyIcon from "../assets/images/Copy-white.svg";
import AddToNotesIcon from "../assets/images/AddToNotes-white.svg";
import AppleLogoWhite from "../assets/images/Apple-Logo-white.svg";
import GooglePlayLogoWhite from "../assets/images/Google-Play-Logo-white.svg";
import WindowsLogoWhite from "../assets/images/Windows-Logo-white.svg";
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (
        event.target == document.getElementById("downloadMenuButton") ||
        event.target ==
          document.getElementById("downloadMenuButton").children[0]
      ) {
        return;
      }
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
export default {
  directives: {
    clickOutside,
  },
  props: {
    pageTitle: String,
    data: Object,
    exitButtonText: String,
    exitButtonFunction: Function,
    exitButtonHelperText: String,
    userFullName: String,
    isOpen: Boolean,
    toggleInfoPanelOpen: Function,
    tooltipInfo: String,
    goToSessionBrowser: Function,
    sessionBrowserPage: Boolean,
    notetakingBrowserPage: Boolean,
    captioningBrowserPage: Boolean,
    openNewMenu: Function,
    sttOption: Boolean,
    captionsLanguageOption: Boolean,
    changeLanguage: Function,
    parentLanguage: Object,
    changeSttType: Function,
    selectedSttType: String,
    settingsOpen: Boolean,
    settingsActiveTabIndex: Number,
    settingsPagesToShow: Array,
    editTitleData: {
      type: Object,
      default: null,
    },
    bookmarkList: {
      type: Array,
      default: null,
    },
    pasteToNote: {
      type: Function,
      default: () => {
        return null;
      },
    },
    openNewSessionModal: {
      type: Function,
      default: () => {},
    }
  },

  components: {
    RoundedButton,
    IconButton,
    LogoHeader,
    Settings,
    TitleChangeModal,
  },

  data() {
    return {
      appName,
      LINKS,
      ChevronLeftWhiteIcon,
      ChevronDownFilledWhite,
      DownloadWhiteIcon,
      SettingsWhiteIcon,
      RoundUpdateWhiteIcon,
      LeaveWhiteIcon,
      ProfileWhiteIcon,
      SessionBrowserWhiteIcon,
      BulletListWhiteIcon,
      SettingsFilledWhite,
      PlusBlackIcon,
      MicrophoneDark,
      UploadDarkIcon,
      PlusWhiteIcon,
      EditIcon,
      ThreeDotsWhiteIcon,
      CloseGrey,
      TrashIcon,
      CopyIcon,
      AddToNotesIcon,
      convertSecondsToMS,
      toggleDownloadMenu: false,
      closeDownloadCard: true,
      settingsOpenState: this.settingsOpen || false,
      settingsActiveTabIndexState: 0,
      AppleLogoWhite,
      GooglePlayLogoWhite,
      WindowsLogoWhite,
      macOSLink: "",
      windowsLink: "",
      items: [
        {
          label: "Record",
          icon: MicrophoneDark,
          command: () => {
            this.$router.push({
              name: "Recorder",
            });
          },
        },
        {
          label: "Upload",
          icon: UploadDarkIcon,
          command: () => {
            this.$router.push({
              name: "Upload",
            });
          },
        },
      ],
      collapsed: false,
      showTitleChangeModal: false,
      menuProps: [
        {
          label: "Color",
          colors: ["#FFD362", "#FFA69A", "#79DCFF", "#02FADA", "#E993FF"],
          icon: ThreeDotsWhiteIcon,
        },
        {
          label: "Copy",
          command: () => {
            this.copyText();
          },
          icon: CopyIcon,
        },
        {
          label: "Add to Notes",
          command: () => {
            this.addToNotes();
          },
          icon: AddToNotesIcon,
        },
        {
          label: "Delete",
          command: () => {
            this.deleteBookmark();
          },
          icon: TrashIcon,
        },
      ],
      seletectedBookmark: null,
    };
  },

  watch: {
    settingsOpen(newVal) {
      this.settingsOpenState = newVal;
    },

    settingsActiveTabIndex(newVal) {
      this.settingsActiveTabIndexState = newVal;
    },
  },

  mounted() {
    this.getLatestReleaseUrls();
  },

  methods: {
    doStuff() {
      if (this.toggleDownloadMenu) {
        this.toggleDownloadMenu = !this.toggleDownloadMenu;
      }
    },
    logout() {
      logout(this.$router);
    },

    goToHome() {
      this.$router.push("/standby");
    },

    openSettings() {
      this.settingsOpenState = true;
      this.$emit("updateSettingsVisible", true);
    },

    closeSettings() {
      this.settingsOpenState = false;
      this.$emit("updateSettingsVisible", false);
    },

    setSettingsActiveTabIndex(index) {
      this.settingsActiveTabIndexState = index;
      this.$emit("updateSettingsActiveTabIndex", index);
    },

    setShowTitleChangeModal(show) {
      this.showTitleChangeModal = show;
    },

    emitTitleChange(newTitle) {
      this.$emit("updateTitle", newTitle);
    },

    formatDate(dateString) {
      const dateList = dateString.split("T")[0].split("-");
      return `${dateList[1]}.${dateList[2]}.${dateList[0]}`;
    },

    getTimestamp(paragraphIndex) {
      const captionLine =
        this.$store.state.sessionDetails.uploadDetails.content.captions.results
          .lines[paragraphIndex];
      return captionLine.start_ms / 1000;
    },

    bookmarkTimestampFormat(paragraphIndex) {
      const seconds = this.getTimestamp(paragraphIndex);
      return seconds >= 3600
        ? convertSecondsToHMS(this.getTimestamp(paragraphIndex))
        : convertSecondsToMS(this.getTimestamp(paragraphIndex));
    },

    toggle(event, elem) {
      this.selectedBookmark = elem;
      this.$refs.menu2[0].toggle(event, elem);
    },

    copyText() {
      try {
        this.selectedBookmark.text &&
          navigator.clipboard.writeText(this.selectedBookmark.text);
      } catch (error) {
        console.error(error);
      }
    },

    addToNotes() {
      this.selectedBookmark.text &&
        this.pasteToNote(`<p>${this.selectedBookmark.text}</p>`);
    },

    async deleteBookmark() {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.uploads.id}/` +
          `bookmarks/${this.selectedBookmark.id}`,
        requestOptions
      );
      const bookmarkData = await response.json();
      if (bookmarkData.status === "success") {
        this.$emit("removeBookmark", this.selectedBookmark);
      }
    },

    async changeBookmarkColor(color) {
      if (color === this.selectedBookmark.color) {
        return;
      }
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        color: color,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.uploads.id}/` +
          `bookmarks/${this.selectedBookmark.id}`,
        requestOptions
      );
      const bookmarkData = await response.json();
      if (bookmarkData.status === "success") {
        this.selectedBookmark.color = color;
        this.$emit("changeBookmarkColor", this.selectedBookmark);
      }
    },

    jumpToTimestamp(paragraphIndex) {
      this.$emit("updateCurrentTime", this.getTimestamp(paragraphIndex));
      this.toggleInfoPanelOpen();
    },

    getLatestReleaseUrls() {
      let requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://api.github.com/repos/notetakingexpress/mp-desktop-releases/releases/latest",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          const arr = data.assets;

          const macOSArr = arr.filter(
            (obj) =>
              obj.browser_download_url.includes(".dmg") &&
              !obj.name.includes("blockmap")
          );
          this.macOSLink = macOSArr[0].browser_download_url;

          const windowsArr = arr.filter(
            (obj) =>
              obj.browser_download_url.includes(".exe") &&
              !obj.name.includes("blockmap")
          );
          this.windowsLink = windowsArr[0].browser_download_url;
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style lang="scss">
.info-panel {
  height: 100%;
  background: map-get($component-colours, info-panel-background);
  position: relative;
  width: $side-panel-width;
  min-width: $side-panel-width;
  overflow: hidden;
  border-right: 0.1rem solid map-get($component-colours, border-light-faint);

  @media only screen and (max-width: 1000px) {
    min-width: $side-panel-width-mobile;
    width: $side-panel-width-mobile;
  }

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    margin: 2.4rem 0;

    &.collapsed {
      flex-direction: column-reverse;
      gap: 3.2rem;
      width: 100%;
      right: 0;
      margin: 2.4rem 0;
    }

    .collapse-button {
      filter: brightness(60%);
      &:hover {
        filter: brightness(100%);
      }
    }
  }

  .expanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: $side-panel-width;
    padding: 1.6rem;
    transition: opacity 0.25s ease-in;

    @media only screen and (max-width: 1000px) {
      width: $side-panel-width-mobile;
    }

    &.show-actions.hide {
      opacity: 1;
      pointer-events: auto;
      padding: unset;
      width: auto;

      header,
      .bottom-section {
        opacity: 0;
        pointer-events: none;
      }
    }

    .top-section {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 0;

      header {
        .logo-header {
          height: 4rem;
          margin-top: 0.8rem;
        }

        h1 {
          margin: 0;
          margin-top: 3.2rem;
          font-size: 2.4rem;
          font-weight: bold;
          font-family: $font-default;
          display: flex;
          align-items: center;

          .go-to-session-browser-button {
            margin-right: 0.8rem;
          }
        }
      }

      .info-blocks {
        overflow: auto;
        padding-right: 0.8rem;
        flex: 1;

        .info-block {
          margin-top: 2.4rem;
          border-bottom: $separator-light;

          h3 {
            margin: 0;
            margin-bottom: 0.4rem;
            color: map-get($component-colours, font-colour-secondary);
            font-weight: bold;
          }

          p {
            margin: 0;
            font-size: 1.8rem;
            color: map-get($component-colours, font-colour-primary);
          }

          .secondary-info {
            font-family: $font-secondary;
            color: map-get($component-colours, font-colour-secondary);
            font-size: 1.6rem;
          }
        }
      }

      .bookmark-blocks {
        padding-right: 0.8rem;
        max-height: 100%;
        overflow-y: scroll;
        flex: 1;

        .bookmark-block {
          margin-top: 2.4rem;
          padding-top: 1rem;
          border-bottom: $separator-light;
          display: flex;
          flex-direction: column;
          align-items: left;
          color: white;

          .bookmark-block-timestamp {
            font-family: $font-secondary;
            border-radius: 1.4rem;
            background-color: map-get($colours, accent-purple);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            height: 1.6rem;
          }

          .bookmark-block-timestamp-hours {
            width: 6rem;
          }

          .bookmark-block-timestamp-mins {
            width: 4rem;
          }

          .bookmark-block-text {
            color: white;
            min-height: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 1rem;
            min-height: 1.5rem;
            max-height: 6rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .bookmark-block-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1rem;
          }
        }
      }

      .actions-wrapper {
        .new-menu-button {
          margin-bottom: 2.4rem;
          padding: 1.8rem 2.4rem 1.8rem 2.2rem;
          line-height: 1.6rem;

          img {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 1rem;
          }
        }
      }
    }

    .bottom-section {
      margin-top: 0.75rem;
      display: flex;
      flex-direction: column;

      .exit-button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 13.5rem;
        margin-top: 1.4rem;

        .helper-text {
          font-family: $font-secondary;
          font-size: 1.2rem;
          color: map-get($component-colours, font-colour-secondary);
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
        }
      }
      .download-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 7.2rem;
        border-radius: 0.8rem;
        white-space: normal;
        border: none;
        outline: none;
        position: relative;
        font-family: $font-secondary;
        padding: 1.1rem 2rem;
        background-color: map-get($colours, accent-purple-2);
        color: map-get($colours, white);
        width: 100%;
        cursor: default;

        &:hover {
          .close-download-card {
            display: block;
          }
        }

        .download-card-text {
          text-align: left;
          h2 {
            font-size: 20px;
            color: map-get($colours, accent-purple);
            margin: 0;
          }
          h3 {
            text-transform: uppercase;
            color: map-get($colours, grey);
            letter-spacing: 0.125rem;
            font-weight: bold;
            font-size: 18px;
            margin: 0;
          }
        }
        .download-icon-button {
          border-radius: 0.4rem;
          border: 1px solid map-get($colours, accent-purple-4);
          padding: 0.6rem 1rem 0.6rem 1rem;
          background: map-get($colours, accent-purple-3);
          margin: 0.5rem 1rem;
          cursor: pointer;
          right: 2rem;

          @media only screen and (min-width: 1000px) {
            margin-left: 4rem;
          }
        }
        .close-download-card {
          display: none;
          margin: 0.2rem;
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
          cursor: pointer;
          background-color: transparent;
          padding: 0;
          img {
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        .download-popup-wrapper {
          .down-chevron {
            width: 2.4rem;

            &.rotate-cc90 {
              transform: rotate(-90deg);
            }
          }

          .option {
            padding: 0rem 1.6rem;
            height: 4rem;
            background: map-get($colours, accent-purple-3);
            color: map-get($colours, white);
            font-size: 1.6rem;
            text-transform: initial;
            font-family: $font-secondary;
            font-weight: initial;
          }

          .menu {
            // padding: 0.8rem 0;
            height: 10rem;
            background: map-get($colours, accent-purple-3);
            border-radius: 0.6rem;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
              0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .platform-options-menu-wrapper {
            position: absolute;
            top: 0;
            display: none;
            border-bottom: 0.8rem solid transparent;
            transform: translateX(-210%) translateY(-102%);

            .platform-options-menu {
              .platform-option {
                background: map-get($colours, accent-purple-3);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &:hover {
                  background: map-get($colours, accent-purple-4);
                  cursor: pointer;
                }

                &.mobile-platform {
                  &:hover {
                    .mobile-options-menu {
                      display: flex;
                    }
                  }
                }
                &.desktop-platform {
                  &:hover {
                    .desktop-options-menu {
                      display: flex;
                    }
                  }
                }

                .platform-option-menu {
                  position: absolute;
                  top: 0;
                  right: 0;
                  transform: translateX(100%);
                  display: none;
                  flex-direction: column;
                  width: 16rem;

                  .platform-option {
                    background: map-get($colours, accent-purple-3);
                    color: map-get($colours, white);
                    font-size: 1.6rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                      background: map-get($colours, accent-purple-4);
                    }

                    .platform-option-logo {
                      margin-right: 0.8rem;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }

          @include rwd(700) {
            .large-screen-menu {
              display: flex;
            }
          }

          @include max-width(700) {
            .small-screen-menu {
              display: flex;
            }
          }
        }
      }

      .user-info-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0.8rem;
        border-radius: 0.8rem;
        font-family: $font-secondary;
        position: relative;

        &:hover {
          background: rgba(135, 117, 148, 0.2);

          .user-info-actions {
            display: block;
          }
        }

        .user-info-actions {
          width: 100%;
          border-radius: 0.8rem;
          border: 0.1rem solid map-get($colours, accent-purple-2);
          overflow: hidden;
          background: map-get($colours, accent-purple-4);
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-100%);
          display: none;

          button {
            display: block;
            width: 100%;
            background: inherit;
            color: map-get($colours, white);
            outline: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 1.6rem;

            @include section-sub-header;

            img {
              margin-right: 0.8rem;
            }
          }

          hr {
            width: calc(100% - 1.6rem - 1.6rem);
            border: none;
            height: 0.1rem;
            background-color: map-get($colours, accent-purple-2);
            color: map-get($colours, accent-purple-2);
            margin: 0 auto;
          }
        }

        .name-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 0.8rem;
          }

          .user-full-name {
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
            font-size: 1.6rem;
          }
        }

        .user-email {
          margin-left: 3.2rem;
          font-size: 1.2rem;
          color: map-get($colours, grey-2);
          overflow: hidden;
          max-width: 100%;
          text-overflow: ellipsis;
        }

        .user-type {
          font-size: 1.2rem;
          margin-left: 3.2rem;
          margin-top: 0.8rem;
        }

        .sign-out-button {
          color: map-get($component-colours, button-font-colour-primary);
          align-self: flex-start;
          margin-left: 3.2rem;
        }
      }
    }
  }

  .question-icon {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.5);
  }
  .tabview {
    margin-top: 4rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .p-tabview-nav {
      background-color: blue;
      width: 100%;
      color: white;
      padding: 0;
      margin: 0;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      border-color: rgba(255, 255, 255, 0.2);

      li.p-tabview-ink-bar {
        background-color: white;
        border-width: 0.1rem;
      }
    }

    .p-tabview-panels {
      background-color: transparent;
      flex: 1;
      overflow: auto;
    }
    .p-tabview-nav-link {
      background-color: yellow;
      width: 16rem;
      display: flex;
      justify-content: center;
      overflow: hidden;
      span.p-tabview-title {
        background-color: transparent;
        width: 16rem;
        color: white;
        text-align: center;
      }
    }
  }

  .p-tabview .p-tabview-nav {
    background-color: transparent;
    li.p-tabview-ink-bar {
      background-color: white;
      border-width: 0.1rem;
    }
  }

  li.p-highlight {
    background-color: transparent;
  }

  li[class="true"] {
    background-color: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
    &li.p-tabview-ink-bar {
      background-color: white;
      border-width: 0.1rem;
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: transparent;
    &li.p-tabview-ink-bar {
      background-color: white;
    }
  }
}
.captioner-confirm-ready {
  // display: none;
}
.new-menu-popup {
  width: $side-panel-width - 3.2rem;
  padding: 0.8rem 0rem;
  button {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.8rem;
    padding: 1.6rem 2.4rem;
    span {
      @include h3;
    }
  }
  @media only screen and (max-width: 1000px) {
    width: $side-panel-width-mobile - 3.2rem;
  }
}
.nav-buttons-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.4rem;
  button {
    background: unset;
    border-radius: 8px 50px 50px 8px;
    flex: 1 0 100%;
    padding: 1.6rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.25rem;
    transition: all 0.15s ease-in-out;
    @include h3;
    color: map-get($colours, white);

    &.active,
    &.hover,
    &:focus-visible {
      color: map-get($colours, white);
      background: map-get($colours, accent-purple);
      filter: unset;
    }
    // &:hover {
    //   background-color: #53435f80;
    //   filter: unset;
    // }
  }
  &.collapsed {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    button {
      border-radius: 10px;
      flex: 0 0 auto;
      padding: 0.8rem;

      img {
        width: 2rem;
        height: 2rem;
      }

      span {
        display: none;
      }
    }
    .nav-button-new {
      background: white;
      margin-bottom: 2rem;
    }
  }

  &.hide {
    display: none;
  }
}

.title-editable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &:hover {
    img {
      display: block;
    }
  }

  img {
    display: none;
  }
}

.table-menu-popup-panel {
  width: 22.4rem;
  height: 17.6rem;
  &.p-menu-overlay {
    background-color: map-get($colours, accent-purple-2);
    z-index: 9999;
  }
  .color-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    .color-button {
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .table-menu-popup-panel-button {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    color: white;
  }
}

.tabview {
  margin-top: 4rem;
  .p-tabview {
    background-color: red;
    .p-tabview-nav {
      background-color: blue;
      width: 100%;
      color: white;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      border-color: rgba(255, 255, 255, 0.2);

      li.p-tabview-ink-bar {
        background-color: white;
        border-width: 0.1rem;
      }
    }
  }

  .p-tabview-panels {
    background-color: transparent;
  }
  .p-tabview-nav-link {
    background-color: yellow;
    width: 16rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    span.p-tabview-title {
      background-color: transparent;
      width: 16rem;
      color: white;
      text-align: center;
    }
  }
}

.p-tabview .p-tabview-nav {
  background-color: transparent;
  li.p-tabview-ink-bar {
    background-color: white;
    border-width: 0.1rem;
  }
}

li.p-highlight {
  background-color: transparent;
}

li[class="true"] {
  background-color: transparent;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
  &li.p-tabview-ink-bar {
    background-color: white;
    border-width: 0.1rem;
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: transparent;
  &li.p-tabview-ink-bar {
    background-color: white;
  }
}
</style>
