<template>
  <Dialog
    :visible="props.visible"
    class="dialog"
    :header="false"
    :closable="false"
  >
    <div class="wrapper">
      <img :src="OnboardingRecordNotesImage" class="record-notes-image" />
      <div class="title">Request professional notes?</div>
      <div class="text">
        Order professional notes with a simple one-time payment. Our experts
        will summarize key points for you.
      </div>
      <div class="buttons">
        <button class="buttons-request" @click="setOrderFormVisible(true)">
          REQUEST NOTES
        </button>
        <button @click="goHome" class="buttons-home">MAYBE LATER</button>
      </div>
    </div>
  </Dialog>
  <RequestNotesOrderForm
    :visible="orderFormVisible"
    :recordingTitle="props.sessionTitle"
    :durationHMS="props.sessionDurationHMS"
    :durationMilliseconds="props.sessionDurationMS"
    :recordingUploadId="props.uploadId"
    :setOrderFormVisible="setOrderFormVisible"
    @order-successful="delayedGoHome"
  ></RequestNotesOrderForm>
</template>

<script setup>
import { ref } from "vue";
import OnboardingRecordNotesImage from "../assets/images/Onboarding-record-notes.png";
import RequestNotesOrderForm from "./Orders/RequestNotesOrderForm.vue";

const props = defineProps({
  sessionTitle: {
    type: String,
    required: true,
  },
  sessionDurationHMS: {
    type: String,
    required: true,
  },
  sessionDurationMS: {
    type: String,
    required: true,
  },
  uploadId: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  setVisible: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(["goHome"]);

const orderFormVisible = ref(false);

function setOrderFormVisible(visible) {
  orderFormVisible.value = visible;
}

function goHome() {
  emit("goHome");
  props.setVisible(false);
}

function delayedGoHome() {
  setTimeout(() => {
    goHome();
  }, 3000);
}
</script>

<style lang="scss">
.dialog {
  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: map-get($colours, accent-purple-2);
    width: 44.8rem;
    border-radius: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    padding: 0 4rem;
    margin: 0;
    padding: 0;
    background-color: map-get($colours, accent-purple-2);
    font-family: $font-secondary;
    border-radius: 1.4rem;

    img {
      width: 21.8rem;
      margin-bottom: 1.6rem;
      // height: 15rem;
    }

    .title {
      font-family: $font-accent;
      margin: 0;
      padding: 0;
      margin-bottom: 2.4rem;
      text-align: left;
      font-size: 2.4;
    }

    .text {
      font-size: 1.4rem;
      margin-bottom: 4rem;
      margin-left: 2.4rem;
      margin-right: 2.4rem;
    }

    .buttons {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.8rem;
      width: 100%;
      .buttons-request {
        width: 30.4rem;
        height: 4.8rem;
        border-radius: 0.8rem;
        background-color: map-get($colours, accent-purple-1);
        color: white;
      }
      .buttons-home {
        color: #bababa;
        background-color: transparent;
        margin: 1.6rem 0;
      }
    }
  }
}
</style>
