import { createRouter, createWebHistory } from "vue-router";
import Document from "../views/Document.vue";
import Uhoh from "../views/Uhoh.vue";
import Captioner from "../views/Captioner.vue";
import Feedback from "../views/Feedback.vue";
import Standby from "../views/Standby.vue";
import Landing from "../views/Landing.vue";
import Recorder from "../views/Recorder.vue";
import RequestNotes from "../views/RequestNotes.vue";
import SessionSetup from "../views/SessionSetup.vue";
import NoteTaker from "../views/NoteTaker.vue";
import SessionInitializer from "../views/SessionInitializer.vue";
import Upload from "../views/Upload.vue";

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/documents/:document",
    name: "Document",
    component: Document,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/:page",
    name: "LoginExtra",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/Signup.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/standby",
    name: "Standby",
    component: Standby,
  },
  {
    path: "/sessionSetup",
    name: "SessionSetup",
    component: SessionSetup,
    beforeEnter: (to, from) => {
      if (from.name !== "Standby") {
        return { name: "Standby" };
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sessionInitializer",
    name: "SessionInitializer",
    component: SessionInitializer,
    beforeEnter: (to, from) => {
      if (from.name !== "Standby") {
        return { name: "Standby" };
      }
    },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/notetaking",
    name: "NoteTaker",
    component: NoteTaker,
    beforeEnter: (to, from) => {
      if (from.name !== "SessionInitializer") {
        return { name: "Standby" };
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Uhoh,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/recorder",
    name: "Recorder",
    component: Recorder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/requestNotes",
    name: "RequestNotes",
    component: RequestNotes,
    beforeEnter: (to, from) => {
      if (from.name !== "Recorder") {
        return { name: "Standby" };
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: Upload,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/captioning",
    name: "Captioner",
    component: Captioner,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Meta Handling
router.beforeEach((to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("tokenMP") == null) {
      return { name: "Login" };
    }
  }
});

export default router;
