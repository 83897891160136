export const LINKS = {
  // CHAT: "ws://128.199.1.20:1234/chat",
  CHAT: "wss://mp-collab.habitatlearn.com/chat`",
  MESSENGER_PIGEON: "http://mp.habitatlearn.com/",
  HABITAT_LEARN: "https://www.habitatlearn.com/",
  HABITAT_LEARN_PRODUCTS: "https://www.habitatlearn.com/company/products",
  HABITAT_LEARN_CONTACT: "https://www.habitatlearn.com/company/contact",
  BEEHIVE: "https://beehive.habitatlearn.com/",
  CORAL: "https://coral.habitatlearn.com/",
  MP_DOWNLOAD_PLAYSTORE:
    "https://play.google.com/store/apps/details?id=com.habitatlearn.messengerpigeon",
  MP_DOWNLOAD_APPSTORE:
    "https://apps.apple.com/ca/app/messenger-pigeon/id6446202447",
};

/**
 * The current user properties to store in the Vuex store.
 * Add properties hesre if convenient access to them is needed throughout the app.
 * Avoiding just storing all properties for security purposes.
 */
export const CURRENT_USER_EXTRACTED_PROPERTIES = [
  "id",
  "email",
  "name",
  "role",
  "location",
];

export const FEEDBACK_FORM_STATUSES = {
  INITIAL: "initial",
  SUCCESS: "success",
  ERROR: "error",
};

export const appName = "Messenger Pigeon";

export const APP_STORE_URL = "https://apps.apple.com/account/subscriptions";

export const DEFAULT_COUNTRY_ISO = "US";

export const SUPPORT_INFO = {
  EMAIL: "support@habitatlearn.com",
  CONTACT_URL: "https://www.habitatlearn.com/company/contact",
  PHONE_NUM: "+1-888-763-2966",
};

export const SOCIALS_URLS = {
  LINKEDIN: "https://www.linkedin.com/company/habitatlearn/",
  INSTAGRAM: "https://www.instagram.com/habitatlearn/?hl=en",
  TWITTER: "https://twitter.com/HabitatLearn",
  YOUTUBE: "https://www.youtube.com/@habitatlearn5155",
};

export const COUNTRIES = [
  { name: "🇨🇦 Canada", code: "CA" },
  { name: "🇬🇧 United Kingdom", code: "GB" },
  { name: "🇺🇸 United States", code: "US" },
  { name: "🏳 Other", code: "XX" },
];

export const COUNTRIES_CURRENCY = {
  CA: {
    symbol: "$",
    abbreviation: "CAD",
  },
  GB: {
    symbol: "£",
    abbreviation: "GBP",
  },
  US: {
    symbol: "$",
    abbreviation: "USD",
  },
  XX: {
    // should match the default (US)
    symbol: "$",
    abbreviation: "USD",
  },
};

export const PRO_NOTES_UNIT_PRICES = {
  US: {
    "24hours": 22, // cents
    "48hours": 18, // cents
  },
  CA: {
    "24hours": 22, // cents
    "48hours": 18, // cents
  },
  GB: {
    "24hours": 18, // pence
    "48hours": 15, // pence
  },
  XX: {
    "24hours": 22, // cents
    "48hours": 18, // cents
  },
};

export const STRIPE_MINIMUM_CHARGE = {
  US: 50, // cents
  CA: 50, // cents
  GB: 30, // pence
  XX: 50, // other, match the default (US)
};

export const LOGIN_SCREENS = {
  SELECT: "select",
  LOGIN: "login",
  SIGNUP: "signup",
  FORGOTPASSWORD: "forgotpassword",
  FORGOTPASSWORD_SUCCESS: "forgotpassword_success",
};

export const SETTINGS_SCREENS = {
  ACCOUNT: "account",
  VISUALS_AND_APPEARANCE: "visuals_and_appearance",
  KEYWORDS_DICTIONARY: "keywords_dictionary",
  OTHER_PREFERENCES: "other_preferences",
};

export const ACCOUNT_TYPES = {
  WORKER: "Worker",
  PERSONAL: "Personal",
  STUDENT: "Student",
};

export const SUBSCRIPTION_MODELS = {
  FREE: {
    type: "free",
    perks: [
      "Limited audio recordings, saved on our cloud*",
      "No credit card required",
      "Pay-per-order notes",
    ],
  },
  MONTHLY: {
    type: "monthly",
    perks: [
      "Unlimited recordings, saved on our cloud*",
      "AI transcripts",
      "Study Assistant",
      "Pay-per-order notes",
    ],
  },
  YEARLY: {
    type: "yearly",
    perks: [
      "Unlimited recordings, saved on our cloud*",
      "AI transcripts",
      "Study Assistant",
      "Pay-per-order notes",
    ],
  },
};

export const ORDER_STATUS = {
  COMPLETE: ["completed"],
  REQUESTED: [
    "pending-payment",
    "pending-task-creation",
    "pending-task-completion",
  ],
  IN_PROGRESS: ["task-in-progress"],
  ERROR: [
    "order-cancelled",
    "order-creation-failed",
    "other",
    "task-cancelled--duplicate",
    "task-cancelled--media-quality",
    "task-cancelled--missing-media",
    "task-cancelled--no-discussion",
    "task-cancelled--other",
    "task-creation-failed",
  ],
};

export const ACCOUNT_TEAMS = {
  DSA: "DSA Instant Service Team",
};

export const NOTES_PANEL_DEFAULTS = {
  FONT_SIZE: "11pt",
};

export const SETTINGS_PAGES = {
  ACCOUNT: {
    index: 0,
    title: "Account",
  },
  SUBSCRIPTION: {
    index: 1,
    title: "Subscription",
  },
  APPEARANCE_AND_ANIMATIONS: {
    index: 2,
    title: "Appearance & Animations",
  },
  FONT_AND_COLOURS: {
    index: 3,
    title: "Font and Colours",
  },
  CAPTIONS_LANGUAGE: {
    index: 4,
    title: "Captions Language",
  },
  STT_MODEL: {
    index: 5,
    title: "Speech-to-Text Model",
  },
  KEYWORDS_DICTIONARY: {
    index: 6,
    title: "Keywords Dictionary",
  },
  SUPPORT: {
    index: 7,
    title: "Support",
  },
};

export const editorConstants = {
  WHITE: "#F6F6F6",
  YELLOW: "#FFEB00",
  LIME: "#89FF00",
  BLUE: "#30C7FF",
  TURQUOISE: "#14ED91",
  FUCHSIA: "#8F7C77",
  LIGHT_TURQUOISE: "#E5F7FA",
  AVERTA: "Averta",
  ARIAL: "Arial",
  OPEN_DYSLEXIC: "Open Dyslexic",
  TAHOMA: "Tahoma",
  COMIC_SANS: "Comic Sans",
  TIMES_NEW_ROMAN: "Times New Roman",
  SIZE_INCREMENT: 4,
  MAX_FONT_SIZE: 42,
  MIN_FONT_SIZE: 10,
  DEFAULT_FONT_SIZE: 18,
  DEFAULT_FONT_BOLD: false,
  DEFAULT_LINE_HEIGHT: 1.4,
  DEFAULT_LETTER_SPACING: 0,

  NEWLINE_CHAR_P: "\n\n",
  NEWLINE_CHAR_BR: "\n",
  WHITESPACES: [" ", "\n"],
  SPEAKER_CHANGE_TAG: "[Speaker change]:",
  INAUDIBLE_TAG: "[inaudible]",
  CROSSTALK_TAG: "[crosstalk]",
  FOREIGN_TAG: "[foreign]",
  SILENCE_TAG: "[silence]",
  MUSIC_TAG: "[music]",
  LAUGHS_TAG: "[laughs]",
  COUGHS_TAG: "[coughs]",
  SNEEZE_TAG: "[sneeze]",
  CRIES_TAG: "[cries]",
  TYPING_TAG: "[typing, keyboard strokes]",
  NOISE_TAG: "[noise interruption]",
  INSTRUCTOR_TAG: "[Instructor]",
  STUDENT_TAG: "[Student]",

  AUTOSPACING_TIME_INCREMENT: 500,
  MIN_AUTOSPACING_TIME: 1000,
  MAX_AUTOSPACING_TIME: 10000,
  SURROUNDING_RELEVANT_CHARACTER_RANGE: 50,
  MAX_DELAY_STEP: 0.5,
  MAX_DELAY_MIN: 2,
  MAX_DELAY_MAX: 20,
  CHARACTERS_ACCUMULATED_THRESHOLD: 250,

  STT_TYPES: {
    GOOGLE: "google",
    SPEECHMATICS: "speechmatics",
    DEEPGRAM: "deepgram",
    DEEPGRAM_DIARIZATION: "deepgram_diarization",
  },
  INPUT_DEVICES: {
    DEFAULT_MICROPHONE: "Default Microphone",
    SYSTEM_AUDIO: "System Audio",
    MIC_AND_SYSTEM_AUDIO: "Microphone and System Audio",
  },

  STUDY_ASSISTANT_TYPES: {
    INQUIRY: "inquiry",
    SELECTABLE: "selectable",
    SELECTED: "selected",
    ANSWER: "answer",
    COPIED: "copied",
    FLASHCARD: "flashcard-prompt",
    TIPS: "tips",
  },
};

export const dbConstants = {
  DATABASE_NAME: "database",

  PREFERENCE_TABLE_NAME: "preferences",
  PREFERENCE_TABLE_KEY: "setting",
  PREFERENCE_TABLE_VALUE: "value",
  PREFERENCE_FONT_SIZE: "fontSize",
  PREFERENCE_FONT_COLOUR: "fontColour",
  PREFERENCE_FONT_BOLD: "fontBold",
  PREFERENCE_FONT_FAMILY: "fontFamily",
  PREFERENCE_LINE_HEIGHT: "lineHeight",
  PREFERENCE_LETTER_SPACING: "letterSpacing",
  PREFERENCE_LANGUAGE: { language: "English (Canada)", code: "en-CA" },

  SESSION_DATA_TABLE_NAME: "sessionData",
  SESSION_DATA_TABLE_KEY: "key",
  SESSION_DATA_TABLE_VALUE: "value",
  SESSION_DATA_LOGGED_IN_USER: "loggedInUser",
  SESSION_DATA_CURRENT_USER: "currentUser",
  DEFAULT_USER: "User",

  SERVICE_TYPE_CAPTIONING: "Live Captioning",
  SERVICE_TYPE_NOTETAKING: "Live Note Taking",

  USER_TYPES: {
    SERVICE_PROVIDER: "service_provider",
    STUDENT: "student",
  },

  CAPTIONING_TRANSCRIPT_S3_FILEPATH: "/transcripts/transcript.json",
};

export const STUDY_ASSISTANT_PROMPT_TYPES = {
  QUIZ_AND_TEST_PREP: {
    text: "Quiz and Test Preparation",
    response: "Awesome! Here are a few prompts that might be of help to you:",
    selectables: [
      {
        text: "Make me a study quiz based on this text.",
        requestValue: "quiz",
        type: "answer",
      },
      {
        text: "Help me study by asking me 5 questions about this information.",
        requestValue: "quizFive", // limit the questions to 5
        type: "answer",
      },
    ],
  },
  STUDY_MATERIAL_ANALYSIS: {
    text: "Study Material Analysis",
    response: "Awesome! Here are a few prompts that might be of help to you:",
    selectables: [
      {
        text: "Make me a list of keywords and topics found in this text.",
        requestValue: "keywords",
        type: "answer",
      },
      {
        text:
          "Are there any resources or articles you can recommend for " +
          "further understanding of this topic?",
        requestValue: "recommendations",
        type: "answer",
      },
      {
        text:
          "Can you provide any additional tips or study strategies for " +
          "mastering this material?",
        requestValue: "tips",
        type: "answer",
      },
    ],
  },
  RESOURCE_AND_REFERENCE_ASSISTANCE: {
    text: "Resource and Reference Assistance",
    response: "Awesome! Here are a few question that might be of help to you:",
    selectables: [
      {
        text:
          "Can you help me create flashcards for the important terms and" +
          " concepts in this lecture?",
        requestValue: "flashcard-prompt",
        type: "answer",
      },
    ],
  },
};

export const STUDY_ASSISTANT_INITIAL_PROMPTS = [
  {
    prompt: STUDY_ASSISTANT_PROMPT_TYPES.QUIZ_AND_TEST_PREP,
    type: "selectable",
    timestamp: new Date(),
  },
  {
    prompt: STUDY_ASSISTANT_PROMPT_TYPES.STUDY_MATERIAL_ANALYSIS,
    type: "selectable",
    timestamp: new Date(),
  },
];

/**
 * Used as a default waveform in case peak data is not available
 **/
export const AUDIO_VISUALIZER_DEFAULT_PEAKS = [
  0.19806763285024145, 0.41545893719806765, 0.3743961352657005,
  0.42028985507246375, 0.4033816425120773, 0.32850241545893716,
  0.4033816425120773, 0.42028985507246375, 0.4130434782608695,
  0.4033816425120773, 0.11835748792270528, 0.1256038647342994,
  0.3357487922705314, 0.3115942028985507, 0.24154589371980678,
  0.19565217391304351, 0.214975845410628, 0.12801932367149751,
  0.0917874396135265, 0.17391304347826078, 0.0917874396135265,
  0.2584541062801932, 0.29710144927536225, 0.14009661835748785,
  0.22463768115942023, 0.32367149758454106, 0.22463768115942023,
  0.13285024154589373, 0.13043478260869562, 0.14251207729468596,
  0.3043478260869565, 0.18115942028985507, 0.18357487922705318,
  0.35265700483091783, 0.36231884057971014, 0.4009661835748792,
  0.37922705314009664, 0.4082125603864734, 0.38888888888888884,
  0.391304347826087, 0.393719806763285, 0.3188405797101449, 0.37922705314009664,
  0.40579710144927533, 0.3357487922705314, 0.2898550724637681,
  0.38405797101449274, 0.2826086956521739, 0.2125603864734299,
  0.13285024154589373, 0.3212560386473429, 0.20531400966183574,
  0.2487922705314009, 0.32850241545893716, 0.40579710144927533,
  0.2584541062801932, 0.2729468599033816, 0.32850241545893716,
  0.3140096618357488, 0.3671497584541063, 0.3864734299516908,
  0.3768115942028985, 0.3405797101449275, 0.32367149758454106,
  0.20531400966183574, 0.3454106280193236, 0.20289855072463767,
  0.29227053140096615, 0.3502415458937198, 0.29710144927536225,
  0.30676328502415456, 0.28743961352657005, 0.3405797101449275,
  0.30676328502415456, 0.35265700483091783, 0.009661835748792237,
  0.2946859903381642, 0.0917874396135265, 0.33091787439613524,
  0.06521739130434773, 0.18599033816425112, 0.05797101449275359,
  0.30676328502415456, 0.3188405797101449, 0.35507246376811596,
  0.38888888888888884, 0.29710144927536225, 0.20772946859903385,
  0.28743961352657005, 0.17391304347826078, 0.22222222222222213,
  0.3405797101449275, 0.19806763285024145, 0.12077294685990339,
  0.24154589371980678, 0.2898550724637681, 0.2681159420289855,
  0.36473429951690817, 0.3188405797101449, 0.3502415458937198,
  0.20772946859903385, 0.3164251207729468, 0.33333333333333326,
  0.2777777777777778, 0.33333333333333326, 0.16425120772946852,
  0.3212560386473429, 0.36956521739130427, 0.30676328502415456,
  0.2777777777777778, 0.33091787439613524, 0.3115942028985507,
  0.3502415458937198, 0.35990338164251207, 0.35265700483091783,
  0.35990338164251207, 0.2125603864734299, 0.17391304347826078,
  0.33816425120772947, 0.23188405797101444, 0.3188405797101449,
  0.17874396135265697, 0.2536231884057971, 0.26086956521739124,
  0.2898550724637681, 0.07246376811594203, 0.07487922705314014,
  0.1545893719806763, 0.17874396135265697, 0.05072463768115946,
  0.30676328502415456, 0.14734299516908217, 0.35748792270531393,
  0.07004830917874393, 0.3140096618357488, 0.2995169082125604,
  0.23429951690821255, 0.3212560386473429, 0.32608695652173914,
  0.2995169082125604, 0.3429951690821256, 0.35748792270531393,
  0.36473429951690817, 0.3671497584541063, 0.1545893719806763,
  0.19565217391304351, 0.36473429951690817, 0.4009661835748792,
  0.2753623188405797, 0.33816425120772947, 0.30676328502415456,
  0.16425120772946852, 0.2584541062801932, 0.13526570048309183,
  0.3091787439613526, 0.06763285024154583, 0.07004830917874393,
  0.2777777777777778, 0.3743961352657005, 0.3454106280193236,
  0.3671497584541063, 0.24396135265700478, 0.4009661835748792,
  0.32367149758454106, 0.2584541062801932, 0.1763285024154589,
  0.2850241545893719, 0.3719806763285024, 0.33333333333333326,
  0.22463768115942023, 0.1908212560386473, 0.36956521739130427,
  0.43478260869565216, 0.3719806763285024, 0.004830917874396033,
  0.06763285024154583, 0.3043478260869565, 0.32850241545893716,
  0.2681159420289855, 0.3188405797101449, 0.35990338164251207,
  0.26570048309178745, 0.33333333333333326, 0.24396135265700478,
  0.33816425120772947, 0.3454106280193236, 0.38405797101449274,
  0.35748792270531393, 0.4106280193236715, 0.4589371980676329,
  0.3405797101449275, 0.33333333333333326, 0.35265700483091783,
  0.2681159420289855, 0.3768115942028985, 0.27053140096618356,
  0.3768115942028985, 0.43478260869565216, 0.4589371980676329,
  0.33816425120772947, 0.3671497584541063, 0.33333333333333326,
  0.35507246376811596, 0.3502415458937198, 0.29227053140096615,
  0.3357487922705314, 0.2632850241545893, 0.3671497584541063,
  0.3212560386473429, 0.30193236714975846, 0.11594202898550718,
  0.30193236714975846, 0.3188405797101449, 0.32367149758454106,
  0.20289855072463767, 0.16183574879227045, 0.463768115942029,
  0.32850241545893716, 0.3719806763285024, 0.3454106280193236,
  0.214975845410628, 0.391304347826087, 0.3164251207729468, 0.30676328502415456,
  0.2946859903381642, 0.36231884057971014, 0.18599033816425112,
  0.35265700483091783, 0.3502415458937198, 0.4009661835748792,
  0.2681159420289855, 0.393719806763285, 0.40579710144927533,
  0.3743961352657005, 0.3502415458937198, 0.36473429951690817,
  0.32850241545893716, 0.3115942028985507, 0.14492753623188406,
  0.04106280193236705, 0.10144927536231874, 0.08695652173913047,
  0.2946859903381642, 0.2584541062801932, 0.23913043478260868,
  0.3140096618357488, 0.214975845410628, 0.23429951690821255,
  0.1545893719806763, 0.11835748792270528, 0.32608695652173914,
  0.23671497584541057, 0.24154589371980678, 0.27053140096618356,
  0.1908212560386473, 0.1545893719806763, 0.04589371980676325,
  0.06763285024154583, 0.11352657004830909, 0.2125603864734299,
  0.1594202898550725, 0.12077294685990339, 0.34782608695652173,
  0.3454106280193236, 0.3502415458937198, 0.2729468599033816,
  0.21739130434782608, 0.32850241545893716, 0.2753623188405797,
  0.2898550724637681, 0.08212560386473426, 0.19806763285024145,
  0.33333333333333326, 0.29710144927536225, 0.33816425120772947,
  0.24637681159420288, 0.3140096618357488, 0.22705314009661834,
  0.20048309178743956, 0.2681159420289855, 0.214975845410628,
  0.04106280193236705, 0.4323671497584541, 0.37922705314009664,
  0.35990338164251207, 0.3429951690821256, 0.3768115942028985,
  0.36231884057971014, 0.3502415458937198, 0.38405797101449274,
  0.3212560386473429, 0.32850241545893716, 0.3454106280193236,
  0.3140096618357488, 0.32608695652173914, 0.35990338164251207,
  0.3502415458937198, 0.429951690821256, 0.4130434782608695, 0.4009661835748792,
  0.3671497584541063, 0.3816425120772946, 0.4082125603864734,
  0.4227053140096618, 0.46135265700483086, 0.4227053140096618,
  0.33091787439613524, 0.3091787439613526, 0.4444444444444444,
  0.28743961352657005, 0.3743961352657005, 0.3719806763285024,
  0.3454106280193236, 0.1932367149758454, 0.02657004830917878,
  0.012077294685990338, 0.2946859903381642, 0.35748792270531393,
  0.3454106280193236, 0.3719806763285024, 0.14492753623188406,
  0.11111111111111115, 0.2681159420289855, 0.0628019323671498,
  0.20048309178743956, 0.14492753623188406, 0.251207729468599,
  0.4323671497584541, 0.3502415458937198, 0.2487922705314009,
  0.3768115942028985, 0.33091787439613524, 0.28743961352657005,
  0.4082125603864734, 0.393719806763285, 0.38405797101449274,
  0.44685990338164255, 0.3719806763285024, 0.2729468599033816,
  0.3719806763285024, 0.4033816425120773, 0.29710144927536225,
  0.38405797101449274, 0.3357487922705314, 0.35265700483091783,
  0.24154589371980678, 0.2850241545893719, 0.3429951690821256,
  0.3357487922705314, 0.34782608695652173, 0.3043478260869565,
  0.2632850241545893, 0.3429951690821256, 0.29227053140096615,
  0.03140096618357481, 0.2487922705314009, 0.35748792270531393,
  0.19806763285024145, 0.3115942028985507, 0.2536231884057971,
  0.4492753623188405, 0.36956521739130427, 0.3405797101449275,
  0.45169082125603865, 0.3115942028985507, 0.427536231884058,
  0.35748792270531393, 0.3864734299516908, 0.33333333333333326,
  0.12077294685990339, 0.002415458937198102, 0.3719806763285024,
  0.391304347826087, 0.35507246376811596, 0.3768115942028985,
  0.3164251207729468, 0.2729468599033816, 0.20531400966183574,
  0.32367149758454106, 0.25603864734299514, 0.1932367149758454,
  0.2536231884057971, 0.30193236714975846, 0.36473429951690817,
  0.3502415458937198, 0.34782608695652173, 0.4130434782608695,
  0.427536231884058, 0.27053140096618356, 0, 0.38888888888888884,
  0.391304347826087, 0.3816425120772946, 0.35507246376811596,
  0.37922705314009664, 0.3864734299516908, 0.37922705314009664,
  0.4033816425120773, 0.35990338164251207, 0.27053140096618356,
  0.3671497584541063, 0.39855072463768115, 0.4033816425120773,
  0.36473429951690817, 0.33816425120772947, 0.26086956521739124,
  0.14734299516908217, 0.19806763285024145, 0.29710144927536225,
  0.33091787439613524, 0.35748792270531393, 0.3454106280193236,
  0.3671497584541063, 0.39855072463768115, 0.33091787439613524,
  0.3864734299516908, 0.2826086956521739, 0.4009661835748792,
  0.30193236714975846, 0.21739130434782608, 0.32850241545893716,
  0.251207729468599, 0.3816425120772946, 0.3454106280193236, 0.2850241545893719,
  0.3188405797101449, 0.38405797101449274, 0.33333333333333326,
  0.38405797101449274, 0.3357487922705314, 0.3864734299516908,
  0.3429951690821256, 0.32367149758454106, 0.38405797101449274,
  0.32608695652173914, 0.16666666666666663, 0.41545893719806765,
  0.3357487922705314, 0.2946859903381642, 0.3961352657004831,
  0.43478260869565216, 0.23188405797101444, 0.2125603864734299,
  0.2850241545893719, 0.19806763285024145, 0.07971014492753617,
  0.3768115942028985, 0.3188405797101449, 0.08212560386473426,
  0.36231884057971014, 0.18840579710144922, 0.3502415458937198,
  0.4396135265700483, 0.391304347826087, 0.39855072463768115,
  0.36956521739130427, 0.391304347826087, 0.36231884057971014,
  0.2801932367149758, 0.16183574879227045, 0.393719806763285,
  0.3454106280193236, 0.35990338164251207, 0.33816425120772947,
  0.35990338164251207, 0.3961352657004831, 0.2850241545893719,
  0.27053140096618356, 0.3164251207729468, 0.3719806763285024,
  0.3864734299516908, 0.18840579710144922, 0.3115942028985507,
  0.4009661835748792, 0.391304347826087, 0.3429951690821256,
  0.37922705314009664, 0.29227053140096615, 0.2753623188405797,
  0.10386473429951684, 0.3140096618357488, 0.33091787439613524,
  0.30193236714975846, 0.2850241545893719, 0.27053140096618356,
  0.30676328502415456, 0.10386473429951684, 0.3864734299516908,
  0.2753623188405797, 0.2995169082125604, 0.32850241545893716,
  0.33333333333333326, 0.35990338164251207, 0.3091787439613526,
  0.24396135265700478, 0.3164251207729468, 0.14734299516908217,
  0.3671497584541063, 0.3864734299516908, 0.3864734299516908,
  0.04347826086956515, 0.3357487922705314, 0.3188405797101449,
  0.2753623188405797, 0.2681159420289855, 0.3188405797101449,
  0.3816425120772946, 0.34782608695652173, 0.14251207729468596,
  0.38888888888888884, 0.36231884057971014, 0.3816425120772946,
  0.30193236714975846, 0.038647342995169115, 0.06521739130434773,
  0.3115942028985507, 0.3429951690821256, 0.3768115942028985,
  0.4009661835748792, 0.35507246376811596, 0.23188405797101444,
  0.391304347826087, 0.35265700483091783, 0.251207729468599,
  0.07971014492753617, 0.038647342995169115, 0.0917874396135265,
  0.07004830917874393, 0.34782608695652173, 0.4082125603864734,
  0.33816425120772947, 0.4710144927536232, 0.3719806763285024,
  0.466183574879227, 0.2753623188405797, 0.4323671497584541, 0.393719806763285,
  0.33816425120772947, 0.14251207729468596, 0.391304347826087,
  0.2898550724637681, 0.28743961352657005, 0.33091787439613524,
  0.13526570048309183, 0.012077294685990338, 0.2125603864734299,
  0.11594202898550718, 0.2801932367149758, 0.33091787439613524,
  0.3140096618357488, 0.2898550724637681, 0.009661835748792237,
  0.32367149758454106, 0.29227053140096615, 0.24637681159420288,
  0.12801932367149751, 0.2753623188405797, 0.08454106280193237,
  0.16666666666666663, 0.08454106280193237, 0.0628019323671498,
  0.3405797101449275, 0.08695652173913047, 0.3164251207729468,
  0.38888888888888884, 0.30193236714975846, 0.36231884057971014,
  0.23671497584541057, 0.3502415458937198, 0.2995169082125604,
  0.30193236714975846, 0.3115942028985507, 0.021739130434782573,
  0.3502415458937198, 0.35990338164251207, 0.3429951690821256,
  0.3091787439613526, 0.02657004830917878, 0.06521739130434773,
  0.3429951690821256, 0.19806763285024145, 0.12801932367149751,
  0.22946859903381644, 0.17874396135265697, 0.35265700483091783,
  0.2801932367149758, 0.3502415458937198, 0.32608695652173914,
  0.3429951690821256, 0.32850241545893716, 0.3188405797101449,
  0.40579710144927533, 0.2995169082125604, 0.2753623188405797,
  0.19806763285024145, 0.0917874396135265, 0.12801932367149751,
  0.04589371980676325, 0.1256038647342994, 0.4009661835748792,
  0.29227053140096615, 0.16425120772946852, 0.20289855072463767,
  0.33091787439613524, 0.19806763285024145, 0.2898550724637681,
  0.26086956521739124, 0.26086956521739124, 0.3502415458937198,
  0.251207729468599, 0.23429951690821255, 0.17391304347826078,
  0.1763285024154589, 0.1545893719806763, 0.3140096618357488,
  0.11835748792270528, 0.10869565217391304, 0.1570048309178744,
  0.10628019323671495, 0.2753623188405797, 0.3140096618357488,
  0.32850241545893716, 0.43478260869565216, 0.4371980676328502,
  0.3719806763285024, 0.42512077294685985, 0.391304347826087, 0.393719806763285,
  0.36231884057971014, 0.4033816425120773, 0.3719806763285024,
  0.24154589371980678, 0.35748792270531393, 0.39855072463768115,
  0.3671497584541063, 0.393719806763285, 0.2729468599033816, 0.3719806763285024,
  0.4227053140096618, 0.2826086956521739, 0.2632850241545893,
  0.06763285024154583, 0.20531400966183574, 0.34782608695652173,
  0.3816425120772946, 0.32367149758454106, 0.2777777777777778,
  0.19806763285024145, 0.3454106280193236, 0.2584541062801932,
  0.2632850241545893, 0.26570048309178745, 0.05797101449275359,
  0.2898550724637681, 0.03381642512077292, 0.3357487922705314,
  0.23913043478260868, 0.16666666666666663, 0.24637681159420288,
  0.34782608695652173, 0.2777777777777778, 0.2681159420289855,
  0.04106280193236705, 0.08212560386473426, 0.021739130434782573,
  0.04589371980676325, 0.1570048309178744, 0.11352657004830909,
  0.29227053140096615, 0.42028985507246375, 0.3671497584541063,
  0.41545893719806765, 0.18599033816425112, 0.3188405797101449,
  0.053140096618357384, 0.17391304347826078, 0.07246376811594203,
  0.17874396135265697, 0.3671497584541063, 0.24396135265700478,
  0.13768115942028974, 0.2801932367149758, 0.37922705314009664,
  0.3164251207729468, 0.2995169082125604, 0.1932367149758454,
  0.3357487922705314, 0.23671497584541057, 0.35748792270531393,
  0.3357487922705314, 0.1932367149758454, 0.3188405797101449,
  0.3671497584541063, 0.3405797101449275, 0.34782608695652173,
  0.35990338164251207, 0.30676328502415456, 0.2632850241545893,
  0.45169082125603865, 0.3212560386473429, 0.2681159420289855,
  0.427536231884058, 0.2584541062801932, 0.4082125603864734,
  0.42028985507246375, 0.33333333333333326, 0.24396135265700478,
  0.18357487922705318, 0.2995169082125604, 0.3212560386473429,
  0.09903381642512081, 0.16183574879227045, 0.11835748792270528,
  0.0917874396135265, 0.11835748792270528, 0.14492753623188406,
  0.2101449275362318, 0.18115942028985507, 0.18840579710144922,
  0.18115942028985507, 0.2198067632850242, 0.22946859903381644,
  0.1763285024154589, 0.16425120772946852, 0.13768115942028974,
  0.14734299516908217, 0.04830917874396135, 0.036231884057971016,
  0.07729468599033806, 0.0917874396135265, 0.10628019323671495,
  0.13526570048309183, 0.10386473429951684, 0.14734299516908217,
  0.23188405797101444, 0.13768115942028974, 0.24154589371980678,
  0.18357487922705318, 0.17391304347826078, 0.20048309178743956,
  0.2101449275362318, 0.214975845410628, 0.2198067632850242,
  0.17149758454106284, 0.14734299516908217, 0.1570048309178744,
  0.11352657004830909, 0.1521739130434782, 0.1594202898550725,
  0.14251207729468596, 0.20772946859903385, 0.2753623188405797,
  0.07971014492753617, 0.2487922705314009, 0.16666666666666663,
  0.14734299516908217, 0.1570048309178744, 0.1570048309178744,
  0.24154589371980678, 0.3719806763285024, 0.1908212560386473,
  0.17149758454106284, 0.18357487922705318, 0.2801932367149758,
  0.14251207729468596, 0.13285024154589373, 0.08454106280193237,
  0.1545893719806763, 0.13285024154589373, 0.18115942028985507,
  0.1256038647342994, 0.20289855072463767, 0.1256038647342994,
  0.13768115942028974, 0.14251207729468596, 0.08695652173913047,
  0.29227053140096615, 0.12077294685990339, 0.11352657004830909,
  0.23188405797101444, 0.23671497584541057, 0.3429951690821256,
  0.463768115942029, 0.2729468599033816, 0.3140096618357488, 0.4106280193236715,
  0.48309178743961356, 0.35990338164251207, 0.36473429951690817,
  0.3188405797101449, 0.34782608695652173, 0.2198067632850242,
  0.17149758454106284, 0.34782608695652173, 0.466183574879227,
  0.3405797101449275, 0.14492753623188406, 0.3671497584541063,
  0.4444444444444444, 0.40579710144927533, 0.1570048309178744,
  0.2729468599033816, 0.2584541062801932, 0.1497584541062801,
  0.2946859903381642, 0.2801932367149758, 0.3140096618357488,
  0.38888888888888884, 0.2632850241545893, 0.42028985507246375,
  0.24396135265700478, 0.32850241545893716, 0.41545893719806765,
  0.36473429951690817, 0.3719806763285024, 0.30676328502415456,
  0.3961352657004831, 0.39855072463768115, 0.4589371980676329,
  0.42512077294685985, 0.35748792270531393, 0.4710144927536232,
  0.38405797101449274, 0.3454106280193236, 0.1932367149758454,
  0.22222222222222213, 0.17391304347826078, 0.23671497584541057,
  0.48792270531400966, 0.41787439613526567, 0.39855072463768115,
  0.1908212560386473, 0.2801932367149758, 0.2995169082125604,
  0.42028985507246375, 0.5120772946859904, 0.4130434782608695,
  0.45169082125603865, 0.44202898550724634, 0.466183574879227,
  0.429951690821256, 0.4323671497584541, 0.4371980676328502, 0.427536231884058,
  0.35265700483091783, 0.36473429951690817, 0.30193236714975846,
  0.3743961352657005, 0.3768115942028985, 0.35507246376811596,
  0.35507246376811596, 0.41545893719806765, 0.24637681159420288,
  0.3140096618357488, 0.466183574879227, 0.38888888888888884, 0.429951690821256,
  0.38405797101449274, 0.45169082125603865, 0.37922705314009664,
  0.391304347826087, 0.3164251207729468, 0.33091787439613524,
  0.4106280193236715, 0.393719806763285, 0.36231884057971014,
  0.3502415458937198, 0.26086956521739124, 0.3115942028985507,
  0.32608695652173914, 0.38888888888888884, 0.38405797101449274,
  0.38888888888888884, 0.3816425120772946, 0.3671497584541063,
  0.4371980676328502, 0.35748792270531393, 0.37922705314009664,
  0.37922705314009664, 0.2487922705314009, 0.463768115942029,
  0.36956521739130427, 0.4589371980676329, 0.42028985507246375,
  0.427536231884058, 0.38888888888888884, 0.36956521739130427,
  0.3502415458937198, 0.3429951690821256, 0.42512077294685985,
  0.36473429951690817, 0.41545893719806765, 0.36231884057971014,
  0.3719806763285024, 0.36231884057971014, 0.41787439613526567,
  0.36231884057971014, 0.14251207729468596, 0.32608695652173914,
  0.2584541062801932, 0.18599033816425112, 0.251207729468599,
  0.3212560386473429, 0.3961352657004831, 0.3671497584541063,
  0.3816425120772946, 0.25603864734299514, 0.37922705314009664,
  0.3743961352657005, 0.23188405797101444, 0.33333333333333326,
  0.27053140096618356, 0.2946859903381642, 0.3816425120772946,
  0.20289855072463767, 0.3188405797101449, 0.10628019323671495,
  0.16183574879227045, 0.3188405797101449, 0.08695652173913047,
  0.12801932367149751, 0.2946859903381642, 0.14492753623188406,
  0.27053140096618356, 0.17874396135265697, 0.23429951690821255,
  0.2101449275362318, 0.14734299516908217, 0.18357487922705318,
  0.35990338164251207, 0.3115942028985507, 0.3743961352657005,
  0.23429951690821255, 0.23671497584541057, 0.28743961352657005,
  0.3188405797101449, 0.2850241545893719, 0.23429951690821255,
  0.2681159420289855, 0.3043478260869565, 0.37922705314009664,
  0.33333333333333326, 0.2681159420289855, 0.3454106280193236,
  0.22946859903381644, 0.3140096618357488, 0.3816425120772946,
  0.463768115942029, 0.20289855072463767, 0.25603864734299514,
  0.4033816425120773, 0.391304347826087, 0.2826086956521739, 0.2801932367149758,
  0.2777777777777778, 0.1570048309178744, 0.11352657004830909,
  0.1256038647342994, 0.038647342995169115, 0.009661835748792237, 1,
];

export const HIGHLIGHT_COLOR_MAP = {
  // color map between hex code and rgba code with opacity
  "#FFD362": "rgba(255, 211, 98, 0.22)", // yellow
  "#FFA69A": "rgba(255, 166, 154, 0.32)", // orange
  "#79DCFF": "rgba(121, 220, 255, 0.32)", // blue
  "#02FADA": "rgba(2, 250, 218, 0.24)", // green
  "#E993FF": "rgba(233, 147, 255, 0.32)", // pink
};
