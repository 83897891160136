<template>
  <button
    :class="[
      'rounded-button',
      active && 'active',
      slim && 'slim',
      tall && 'tall',
      taller && 'taller',
      mediumwide && 'medium-wide',
      wide && 'wide',
      extrawide && 'extrawide',
      shadow && 'shadow',
      dark && 'dark',
      darker && 'darker',
      image && 'image',
      text && image && 'text-and-image',
      reverse && 'reverse',
      medium && 'medium',
      bold && 'bold',
      smallerFont && 'smaller-font',
      largerFont && 'larger-font',
      fitWidth && 'fit-width',
      fitHeight && 'fit-height',
      outline && 'outline',
      transparent && 'transparent',
      monospace && 'monospace',
      lessRound && 'less-round',
      moreRound && 'more-round',
      accent && 'accent',
      grey && 'grey',
      morePadding && 'more-padding',
      imageWithoutPadding && 'image-without-padding',
      uppercase && 'uppercase',
      red && 'red',
    ]"
    :disabled="disabled"
    @click="onClick"
    :type="type"
  >
    <slot></slot>
    <span class="text">{{ text }}</span>
    <img
      v-if="image"
      :src="image"
      :class="[
        smallerImage && 'smaller-image',
        biggerGap && 'bigger-gap',
        rightIcon && 'right-icon',
      ]"
    />
    <ProgressSpinner v-if="loading" strokeWidth="5" class="spinner bottom" />
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    active: Boolean,
    primary: Boolean,
    onClick: Function,
    image: String,
    smallerImage: Boolean,
    smallerFont: Boolean,
    largerFont: Boolean,
    text: String,
    slim: Boolean,
    mediumwide: Boolean,
    wide: Boolean,
    extrawide: Boolean,
    shadow: Boolean,
    dark: Boolean,
    darker: Boolean,
    type: String,
    reverse: Boolean,
    medium: Boolean,
    bold: Boolean,
    fitWidth: Boolean,
    fitHeight: Boolean,
    outline: Boolean,
    transparent: Boolean,
    monospace: Boolean,
    lessRound: Boolean,
    moreRound: Boolean,
    accent: Boolean,
    morePadding: Boolean,
    grey: Boolean,
    biggerGap: Boolean,
    imageWithoutPadding: Boolean,
    loading: Boolean,
    tall: Boolean,
    taller: Boolean,
    uppercase: Boolean,
    rightIcon: Boolean,
    red: Boolean,
  },
};
</script>

<style lang="scss">
.rounded-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: map-get($component-sizes, button-height);
  padding: 0 1.4rem;
  border-radius: 2.4rem;
  font-size: 1.4rem;
  white-space: nowrap;
  border: none;
  outline: none;
  position: relative;
  font-family: $font-secondary;
  line-height: 1.6rem;

  &[disabled] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);
  }

  &[hover] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);
  }

  &.active {
    background-color: map-get($colours, accent-purple-3);
    color: map-get($colours, white);
  }

  &.grey {
    background-color: map-get($colours, grey-2);
  }

  &.white {
    background-color: map-get($colours, white);
  }

  &.image {
    padding: 0 1rem;
  }

  &.more-padding {
    padding: 0 2.4rem;
  }

  &.outline {
    color: map-get($component-colours, button-font-colour-accent);
    border: 0.2rem solid map-get($component-colours, button-colour-accent);
    background: transparent;

    &:hover,
    &:active &:not([disabled]) {
      background: map-get($component-colours, button-colour-accent);
      color: map-get($component-colours, button-font-colour-accent);
      filter: none;
    }
  }

  &.monospace {
    text-transform: uppercase;
    letter-spacing: 0.125rem;
  }

  &.fit-width {
    width: 100%;
  }

  &.fit-height {
    height: 100%;
  }

  &.smaller-font {
    font-size: 1.4rem;
  }

  &.larger-font {
    font-size: 1.6rem;
  }

  &.transparent {
    background: transparent;
  }

  &.dark {
    background: map-get($component-colours, button-colour-dark-50);
    color: map-get($component-colours, button-font-colour-primary);
  }

  &.darker {
    background: map-get($component-colours, button-colour-dark-2);
    color: map-get($component-colours, button-font-colour-primary);

    &.outline {
      border: 0.2rem solid map-get($component-colours, button-colour-dark-2);
      background: transparent;
      color: map-get($component-colours, button-font-colour-dark);

      &:hover,
      &:active &:not([disabled]) {
        background: map-get($component-colours, button-colour-dark-2);
        color: map-get($component-colours, button-font-colour-primary);
      }
    }
  }

  &.accent {
    background: map-get($component-colours, button-colour-accent);
    color: map-get($component-colours, button-font-colour-accent);

    &[disabled] {
      background-color: map-get($colours, grey-1);
      color: map-get($component-colours, button-font-colour-disabled);
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    img {
      margin-left: 0;
      margin-right: 0.2rem;

      &.bigger-gap {
        margin-right: 0.8rem;
      }
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.medium {
    font-weight: 500;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.125rem;
  }

  &.red {
    color: map-get($colours, white);
    background: map-get($colours, red);
  }

  // .text {
  // margin-top: 0.2rem;
  // line-height: 0.8;
  // }

  img {
    margin-left: 1rem;

    &.smaller-image {
      height: 70%;
    }

    &.right-icon {
      position: absolute;
      right: 1.6rem;
    }
  }

  &.less-round {
    border-radius: 0.8rem;
  }

  &.more-round {
    border-radius: 3.2rem;
  }

  .p-progress-spinner {
    height: 20px;
    width: 20px;
    margin: 0;
    margin-left: 1.2rem;
  }

  .p-progress-spinner-circle {
    // Hide spinner before animation starts
    stroke: none;
    // stroke: rgba(255, 255, 255, 0.5);
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
      p-progress-spinner-color-dark 6s ease-in-out infinite;
    -webkit-animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
      p-progress-spinner-color-dark 6s ease-in-out infinite;
    animation-delay: -2.5s;
  }
}

.rounded-button.taller {
  height: 5.6rem;
}

.rounded-button.tall {
  height: map-get($component-sizes, button-height-tall);
}

.rounded-button.slim {
  height: map-get($component-sizes, button-height-slim);
}

.rounded-button.medium-wide {
  min-width: map-get($component-sizes, button-min-width-medium-wide);
}

.rounded-button.wide {
  min-width: map-get($component-sizes, button-min-width-wide);
}

.rounded-button.extrawide {
  min-width: map-get($component-sizes, button-min-width-extrawide);
}

.rounded-button.shadow {
  box-shadow: $small-crisp-box-shadow;
}

.rounded-button.text-and-image:not(.image-without-padding) {
  .text {
    // Balances the whitespace to make content look more centered
    margin-left: 0.4rem;
  }
}
</style>
