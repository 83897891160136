<template>
  <Dialog
    :visible="visible"
    modal
    class="request-notes-dialog"
    :header="false"
    :closable="false"
  >
    <IconButton
      :icon="CloseGreyIcon"
      class="close-button"
      alt="Close menu"
      :onclick="
        () => {
          this.setOrderFormVisible(false);
        }
      "
      xsmall
    />
    <header v-if="step === 0">
      <h2>Order Summary</h2>
    </header>
    <Transition mode="out-in">
      <div v-if="step === 1">
        <header class="header-with-back-button">
          <button @click="back" class="back-button">
            <img :src="ChevronLeftWhiteIcon" alt="Back" />
          </button>
          <h2>Payment details</h2>
        </header>
        <form
          class="payment-details-body"
          @submit.prevent="submitOrder"
          v-if="!orderSubmitted"
        >
          <div class="payment-info">
            <label for="stripeInput">Card information</label>
            <div class="stripe-input-wrapper payment-input" id="stripeInput">
              <StripeElements
                v-if="stripe"
                v-slot="{ elements }"
                ref="elms"
                :stripe-key="stripeKey"
                :instance-options="instanceOptions"
                :elements-options="elementsOptions"
              >
                <StripeElement
                  ref="card"
                  :elements="elements"
                  :options="cardOptions"
                />
              </StripeElements>
            </div>
          </div>
        </form>
      </div>
    </Transition>
    <Transition mode="out-in">
      <div v-if="!orderSubmitted">
        <Message
          v-if="isOrderPriceBelowStripeMinimum"
          severity="warn"
          :closable="false"
          icon="pi pi-info-circle"
          >The minimum order amount is {{ stripeMinimumChargeFormatted }}. If
          your order is below this amount, it will be adjusted to meet the
          minimum.
        </Message>
        <OrderSummaryCard
          ref="orderSummaryCard"
          :title="recordingTitle"
          :durationHMS="durationHMS"
          :durationMilliseconds="durationMilliseconds"
          :costRate="proNotesHourlyRate"
          :isOrderPriceBelowStripeMinimum="isOrderPriceBelowStripeMinimum"
          :formattedOrderPrice="formattedOrderPrice"
          class="order-summary-card"
        ></OrderSummaryCard>
        <div
          v-if="this.$store.state.currentUser.role === 'Org User'"
          class="org-user-message-card"
        >
          <div class="message-header">
            <img :src="CheckmarkOutline" class="org-message-card-checkmark" />
            <p class="header-text">Paid by Organization</p>
          </div>
          <p class="org-message-body-text">
            You won’t need to make any payment as it has already been handled by
            your organization.
          </p>
        </div>
        <p v-if="orderSubmissionErrorMessage !== ''" class="error-message">
          {{ orderSubmissionErrorMessage }}
        </p>
        <RoundedButton
          :text="buttonText"
          accent
          tall
          fitWidth
          lessRound
          uppercase
          class="action-button"
          @click="nextStep"
          :loading="orderSubmissionLoading"
          :disabled="orderSubmissionLoading"
        />
      </div>
      <div v-else class="order-confirmation">
        <img :src="Checkmark" class="checkmark" />
        <div class="thank-you">Thank you for your order</div>
        <p class="confirmation-blurb">
          Your order is confirmed. <br />You’ll be notified when your notes are
          done.
        </p>
      </div>
    </Transition>
  </Dialog>
</template>

<script>
import OrderSummaryCard from "../Orders/OrderSummaryCard.vue";
import IconButton from "../../components/IconButton";
import RoundedButton from "../../components/RoundedButton";
import CloseGreyIcon from "../../assets/images/Close-grey.svg";
import ChevronLeftWhiteIcon from "../../assets/images/Chevron-left-white-2.svg";
import Checkmark from "../../assets/images/Checkmark-Order-Confirmed.svg";
import CheckmarkOutline from "../../assets/images/Checkmark-white-outline.svg";
import { loadStripe } from "@stripe/stripe-js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import {
  DEFAULT_COUNTRY_ISO,
  PRO_NOTES_UNIT_PRICES,
  STRIPE_MINIMUM_CHARGE,
} from "../../assets/constants";
import {
  calculateOrderPriceInCents,
  getFormattedPrice,
} from "../../assets/helpers";

export default {
  setup() {
    const stripe = ref(null);
    const card = ref(null);
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const store = useStore();

    const stripeKey = computed(() => {
      switch (store.state.currentUser.location) {
        case "CA":
          return process.env.VUE_APP_STRIPE_PK_CA;
        case "GB":
          return process.env.VUE_APP_STRIPE_PK_GB;
        case "US":
          return process.env.VUE_APP_STRIPE_PK_US;
        default:
          return process.env.VUE_APP_STRIPE_PK_US;
      }
    });

    onMounted(async () => {
      const res = await loadStripe(stripeKey.value);
      stripe.value = res;
    });

    return {
      stripe,
      stripeKey,
      card,
      instanceOptions,
      elementsOptions,
    };
  },

  components: {
    OrderSummaryCard,
    IconButton,
    RoundedButton,
    StripeElements,
    StripeElement,
  },

  props: {
    visible: Boolean,
    recordingTitle: String,
    durationHMS: String,
    durationMilliseconds: String,
    recordingUploadId: String,
    setOrderFormVisible: Function,
    menuItem: Object,
  },

  computed: {
    currentUserCountryISO() {
      return this.$store.state.currentUser.location || DEFAULT_COUNTRY_ISO;
    },

    proNotesHourlyRate() {
      return (
        (PRO_NOTES_UNIT_PRICES[this.currentUserCountryISO]["48hours"] * 60) /
        100
      ).toFixed(2);
    },

    stripeMinimumCharge() {
      return STRIPE_MINIMUM_CHARGE[this.currentUserCountryISO];
    },

    stripeMinimumChargeFormatted() {
      return getFormattedPrice(
        (this.stripeMinimumCharge / 100).toFixed(2),
        this.currentUserCountryISO
      );
    },

    isOrderPriceBelowStripeMinimum() {
      return (
        calculateOrderPriceInCents(
          this.durationMilliseconds,
          this.currentUserCountryISO
        ) < this.stripeMinimumCharge
      );
    },

    subtotalDollars() {
      let orderPriceInCents = calculateOrderPriceInCents(
        this.durationMilliseconds,
        this.currentUserCountryISO
      );

      // Stripe has a minimum charge, if order price is below that then set it to Stripe's minimum charge amount
      if (this.isOrderPriceBelowStripeMinimum) {
        orderPriceInCents = this.stripeMinimumCharge;
      }

      return (orderPriceInCents / 100).toFixed(2);
    },

    formattedOrderPrice() {
      return getFormattedPrice(
        this.subtotalDollars,
        this.currentUserCountryISO
      );
    },
  },

  data() {
    return {
      CloseGreyIcon,
      ChevronLeftWhiteIcon,
      Checkmark,
      CheckmarkOutline,
      buttonText: "PLACE ORDER",
      step: 0,
      orderSubmissionErrorMessage: "",
      genericErrorMessage: "Sorry, there was an error in submitting this order",
      orderSubmissionLoading: false,
      cardOptions: {
        style: {
          base: {
            iconColor: "#c4f0ff",
            color: "#fff",
            fontWeight: "500",
            fontFamily: "Manrope, Roboto, Open Sans, Segoe UI, sans-serif",
            "::placeholder": {
              color: "#bababa",
            },
          },
          invalid: {
            iconColor: "#eb5b5b",
            color: "#eb5b5b",
          },
        },
      },
      orderSubmitted: false,
    };
  },

  watch: {
    step: function () {
      if (this.step === 1) {
        this.buttonText = "PAY " + this.formattedOrderPrice;
      }
    },
  },

  methods: {
    nextStep() {
      if (this.$store.state.currentUser.role === "Org User") {
        this.submitOrder();
      } else if (this.step === 1) {
        this.submitOrder();
      } else {
        this.step++;
      }
    },

    back() {
      this.step--;
      this.buttonText = "PLACE ORDER";
    },

    async submitOrder() {
      if (this.orderSubmissionLoading) {
        // Verbosely prevent multiple orders while one is in progress
        return;
      }

      let stripeElement = null;
      let stripeElements = null;
      this.orderSubmissionLoading = true;

      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        uploadId: this.recordingUploadId,
        orderType: "pro-notes",
        turnaroundTime: "48hours",
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL + "self/orders",
        requestOptions
      );
      const data = await response.json();

      if (data.status === "error") {
        this.orderSubmissionErrorMessage = this.genericErrorMessage;
      } else if (data.status === "success") {
        // Handle card payment (if !Org User)
        if (this.$store.state.currentUser.role !== "Org User") {
          stripeElement = this.$refs.card.stripeElement;
          stripeElements = this.$refs.elms;

          stripeElements.instance
            .confirmCardPayment(data.data.paymentIntent.client_secret, {
              payment_method: {
                card: stripeElement,
              },
            })
            .then((result) => {
              if (result.error) {
                this.orderSubmissionErrorMessage = this.genericErrorMessage;
                this.orderSubmissionLoading = false;
                return;
              } else {
                // Successful order
                this.$emit("orderSuccessful");
                this.orderSubmitted = true;
              }
            })
            .catch(() => {
              this.orderSubmissionErrorMessage =
                "There was an error processing your order";
              this.$emit("orderSuccessful");
              this.orderSubmitted = true;
            });
        } else {
          this.$emit("orderSuccessful");
          this.orderSubmitted = true;
        }
      }
      this.$emit("updateStandbySessionsMenuItem");
    },
  },
};
</script>

<style lang="scss">
.request-notes-dialog {
  background-color: map-get($colours, accent-purple-2);
  color: map-get($colours, white);
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2),
    0 0.3rem 1.4rem 0 rgba(0, 0, 0, 0.12), 0 0.8rem 1rem 0 rgba(0, 0, 0, 0.14);
  width: 50rem;
  padding: 0;
  text-align: center;

  .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: inherit;
    color: inherit;
    padding: 3.2rem 2.4rem 4rem;
    overflow-x: hidden;
  }

  .order-summary-card {
    margin-bottom: 4.8rem;
  }

  .error-message {
    font-size: 1.2rem;
    color: map-get($colours, red);
  }

  /* we will explain what these classes do next! */
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .header-with-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1,
    h2 {
      margin: 0;
    }

    .back-button {
      margin-right: 2.4rem;
      background: none;

      img {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  .subscriptions-body {
    > *:not(:first-child) {
      margin-top: 1.6rem;
    }

    .subscription-option {
      background-color: map-get($colours, accent-purple-3);
      border-radius: 1.4rem;
      padding: 1.6rem;
      font-family: $font-secondary;
      border: 0.1rem solid rgba(0, 0, 0, 0);
      transition: border 0.3s ease-in-out;
      cursor: pointer;

      &.selected {
        border-color: map-get($colours, accent-purple);
      }

      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        color: map-get($colours, accent-purple);
      }

      .price {
        margin-top: 0.8rem;
      }

      .features {
        font-size: 1.4rem;
        color: map-get($colours, grey-3);
        margin: 1.6rem 0 1.8rem;
        list-style-image: url("../../assets/images/Checkmark-purple.svg");
        padding-left: 1.6rem;

        li {
          padding-left: 0.8rem;
        }

        > *:not(:first-child) {
          margin-top: 1.2rem;
        }
      }

      .disclaimer {
        font-size: 1rem;
        color: map-get($colours, translucent-white-60);
        text-align: center;
      }
    }
  }

  .org-user-message-card {
    font-size: 1.4rem;
    font-family: $font-secondary;
    background-color: #2a5136;
    border-radius: 1.4rem;
    padding: 1.6rem;
    margin-top: 2.4rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12),
      0 1px 1px 0 rgba(0, 0, 0, 0.14);
    text-align: left;
    margin-top: -2rem;
    margin-bottom: 2rem;

    .message-header {
      display: inline-flex;
      font-size: 1.6rem;
      font-weight: bold;

      .org-message-card-checkmark {
        height: 2.2rem;
        width: 2.2rem;
        margin-top: 1rem;
        margin-right: 1rem;
      }

      .header-text {
        margin-top: 1rem;
      }
    }
    .org-message-body-text {
      margin-left: 3.5rem;
      margin-top: -1rem;
    }
  }

  .payment-info {
    text-align: left;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;

    label {
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 1.4rem;
      color: map-get($colours, grey);
    }

    .location-fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .location-field {
        display: flex;
        flex-direction: column;
        margin-top: 1.6rem;
        width: 50%;
      }
    }
  }

  .order-submission-error-message {
    font-size: 1.4rem;
  }

  .subscription-overview-card {
    margin-bottom: 1.6rem;
  }

  .payment-button {
    margin-top: 2.4rem;
  }

  .payment-input {
    border: 0.2rem solid map-get($colours, accent-purple);
    padding: 1rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    background: transparent;
    color: map-get($colours, white);
    margin-top: 0.8rem;

    &:hover {
      border: 0.2rem solid map-get($colours, accent-purple-1);
    }

    &:focus {
      border: 0.2rem solid map-get($colours, accent-purple);
    }

    &::placeholder,
    .p-dropdown-label.p-placeholder,
    .p-dropdown-trigger {
      color: map-get($colours, grey-3);
    }

    .p-inputtext {
      color: map-get($colours, white);
      font-size: 1.4rem;
    }

    .p-dropdown-label {
      padding: 0;
    }
  }
  .order-confirmation {
    text-align: center;
    .checkmark {
      margin-top: 9rem;
    }
    .thank-you {
      font-family: Averta;
      font-size: 2.4rem;
      font-weight: bold;
      font-stretch: normal;
      margin-top: 4rem;
    }
    .confirmation-blurb {
      font-size: 1.6rem;
      margin-bottom: 7.2rem;
    }
    .back-home-button {
      margin: 0 auto;
      color: #fff;
      width: 160px;
      height: 56px;
      padding: 16px 24px;
      border-radius: 32px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
        0 3px 14px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.14);
      background-color: #877594;
    }
  }

  .successful-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 1.6rem;

    header {
      h2 {
        margin: 2.4rem 0;
      }
    }

    p {
      font-size: 1.6rem;
      width: 37rem;
      margin: 1.6rem 0;
      white-space: pre-line;
    }

    .action-button {
      margin-top: 5.6rem;
    }
  }
}
</style>
