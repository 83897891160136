<template>
  <div
    class="pro-note-status"
  >
    <div
      v-if="$store.state.sessionDetails.status === 'no-value'"
      class="pro-note-status-display status-no-value"
    >
      <div
        class="status-no-value-title" 
      >
        Want professional notes for this recording?
      </div>
      <button
        class="status-no-value-button"
        @click="openRequestNoteForm"
      >
        <img :src="NotesPurpleIcon" />
        Request notes
      </button>
    </div>
    <div
      v-if="
        $store.state.sessionDetails.status === 'requested' ||
        $store.state.sessionDetails.status === 'in-progress' 
      "
      class="pro-note-status-display status-preparing-notes"
    >
      <div
        class="status-preparing-notes-title"
      >
        Hang tight!
      </div>
      <div
        class="status-preparing-notes-text"
      >
        Your requested notes are on its way.
      </div>
      <img
        :src=PreparingTranscriptImage
        class="status-preparing-notes-image"
      />
      <div
        class="status-preparing-notes-text"
      >
        We will notify you when it's ready.
      </div>
      <div
        class="status-preparing-notes-text"
      >
        Feel free to close the app and come back later.
      </div>

    </div>
    <div
      v-if="$store.state.sessionDetails.status === 'error'"
      class="pro-note-status-display status-error"
    >
      <div class="status-error-icon">
        <div class="status-error-icon-2">
          <img
            :src="CloseGreyIcon"
          />
        </div>
      </div>
      <div
        class="status-error-title"
      >
        {{ errorMessage.title }}
      </div>
      <div
        class="status-error-text"
      >
        {{ errorMessage.text }}
      </div>
      <div
        v-if="
          $store.state.sessionDetails.statusDetail === 
          'order-creation-failed'
        ">
        <button
          @click="openRequestNoteForm"
        >
          Try again
        </button>
      </div>
      <div
        class="status-error-text status-error-line"
      >
        If you continue to experience issues or have further
        questions, please don't hesitate to reach out to our
        <a
          href="https://www.habitatlearn.com/company/contact"
          class="status-error-text"
          >support team</a
        >.
      </div>
    </div>
  </div>
</template>

<script>
import NotesPurpleIcon from "../../assets/images/Notes-purple.svg";
import PreparingTranscriptImage from "../../assets/images/PreparingTranscript.png";
import CloseGreyIcon from "../../assets/images/Close-grey.svg";
import {
  SUPPORT_INFO,
} from "../../assets/constants";

export default {
  props: {
    openRequestNoteForm: Function,
  },
  data() {
    return {
      NotesPurpleIcon,
      CloseGreyIcon,
      PreparingTranscriptImage,
      SUPPORT_INFO,
    };
  },
  computed: {
    errorMessage() {
      const message = {
        title: "",
        text: "",
      };
      switch (this.$store.state.sessionDetails.statusDetail) {
        case "order-canceled":
          message.title = "Order cancelled";
          message.text = "Your order has been cancelled as requested.";
          break;
        case "order-creation-failed":
          message.title = "Order creation failed";
          message.text = "We encountered an issue while creating your order" +
            " and couldn't proceed. Please try again later.";
          break;
        case "task-cancelled--duplicate":
          message.title = "Duplicate order detected";
          message.text = "Due to an unexpected issue on our end, a duplicate" +
            "of your order was created. We've cancelled the duplicate to " +
            "ensure smooth processing of your original request.";
          break;
        case "task-cancelled--media-quality":
          message.title = "Request cancelled: Media quality issue";
          message.text = "Unfortunately, we had to cancel your request due to" +
            "due to media quality issues that prevent us from proceeding.\n\n" +
            "Uploading media in a higher resolution may resolve this issue." +
            "Please start a new session with media that meets our quality " + 
            "standards.";
          break
        case "task-cancelled--missing-media":
          message.title = "Media upload missing";
          message.text = "Unfortunately, we've identified a problem where the" +
            " media for your request is missing due to an error on our end. " + 
            "We're addressing this with high priority. \n\n" +
            "We kindly ask you to submit your media again in a new session. " +
            "We apologize for this inconvenience and thank you for your" +
            " patience.";
          break;
        case "task-cancelled--no-discussion":
          message.title = "Insufficient content for notes"
          message.text = "Unfortunately, we had to cancel your request " +
            "because we couldn't find sufficient content to take notes on -" +
            " this could be due to no discussion, a recording that's too " + 
            "short, or unclear media issues. Please make sure everything " +
            "needed has ben shared with us.";
          break;
        default:
          message.title = "Something went wrong";
          message.text = "Your task has been cancelled due to an unexpected " +
            "technical issue on our end. While we work on a solution, we " +
            "appreciate your patience and understanding\n\n" +
            "We kindly ask you to create a new session to proceed your order." +
            " We apologize for this inconvinience and thank you for " +
            "your patience."
      }
      return message;
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.pro-note-status {
  display: flex;
  justify-content: center;

  .pro-note-status-display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .status-no-value {
    .status-no-value-title {
      margin: 3.2rem 0;
      font-size: 1.6rem;
      font-weight: bold;
    }

    .status-no-value-button {
      background: white;
      color: map-get($colours, grey-7);
      font-size: 1.6rem;
      border-radius: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17.6rem;
      height: 4.8rem;
    }
  }

  .status-preparing-notes {
    .status-preparing-notes-title {
      margin: 4.0rem 0 0;
      font-size: 1.8rem;
      font-weight: bold;
    }

    .status-preparing-notes-image {
      margin: 1.6rem 0;
    }

    .status-preparing-notes-text {
      margin: 0.8rem 0 0;
      font-size: 1.6rem;
      font-family: $font-secondary;
    }
  }

  .status-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4.0rem 5.6rem;
    text-align: center;
    font-family: $font-secondary;
    button {
      width: 16.8rem;
      height: 5.6rem;
      background: map-get($colours, accent-purple);
      color: white;
      margin: 3.8rem 0 3.8rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
    }

    img {
      height: 3.11rem;
      width: 3.11rem;
    }

    .status-error-icon {
      border-radius: 50%;
      background-color: rgba(59, 44, 71, 0.78);
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .status-error-icon-2 {
        border-radius: 50%;
        background-color: rgba(59, 44, 71, 0.54);
        width: 4.98rem;
        height: 4.98rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      margin-bottom: 2.4rem;
    }
    .status-error-title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.0rem;
      font-family: $font-default;
    }

    .status-error-text {
      font-size: 1.4rem;
      margin: 1.4rem 0;
      white-space: pre-wrap;
      a {
        color: white;
        font-weight: bold;
      }
    }

    .status-error-line {
      border-top: 1px solid map-get($colours, accent-purple);
      padding: 4.0rem 0;
      margin: 0;
    }
  }
}

</style>

