<template>
  <div class="wrapper">
    <div class="title">Save Session</div>
    <div class="form">
      <div class="form-title">
        <span class="duration-label">Session Duration</span>
        <span>
          {{
            addZero(recordingDuration[0]) +
            " hrs " +
            addZero(recordingDuration[1]) +
            " mins " +
            addZero(recordingDuration[2]) +
            " secs"
          }}
        </span>
      </div>
      <div class="form-text">
        Please click Save after providing the recording details. Your recording
        will be securely saved in your habitat account for future access and
        reference.
      </div>
      <div class="label">TITLE</div>
      <span class="p-input-icon-right input-wrapper">
        <InputText
          type="text"
          v-model="title"
          class="form-input"
          @focus="showClear = true"
          @blur="delayRemove"
          @keydown="filterKey"
        />
        <i class="pi">
          <div class="clear-button-wrapper">
            <IconButton
              :icon="CloseCircleIcon"
              class="clear-icon"
              alt="Clear text"
              :onclick="clearText"
              xxxsmall
              v-if="showClear"
            />
          </div>
        </i>
      </span>
      <button
        class="details-button label"
        @click="
          () => {
            toggleDetail = !toggleDetail;
          }
        "
      >
        <img
          :src="ArrowIcon"
          class="details-button-icon"
          :class="toggleDetail ? 'arrow-up' : 'arrow-down'"
        />
        Advanced details
      </button>
      <Transition name="slide-up-slow" mode="out-in">
        <div v-if="toggleDetail" class="details-section">
          <div class="label">SUBJECT MATTER</div>
          <AutoComplete
            v-model="selectedSubject"
            :suggestions="filteredSubjects"
            @complete="search"
            optionLabel="label"
            optionGroupLabel="label"
            optionGroupChildren="items"
            dropdownMode="current"
            dropdown
            class="form-input center-items input-wrapper"
            placeholder="Select or type..."
            emptySearchMessage="No matching subjects..."
            forceSelection
          >
            <template #optiongroup="slotProps">
              <div class="item-group">
                <div>{{ slotProps.item.label }}</div>
              </div>
            </template>
          </AutoComplete>
          <div class="label">TAGS</div>
          <TreeSelect
            v-model="selectedTags"
            :options="nodes"
            selectionMode="multiple"
            placeholder="Select..."
            display="chip"
            :metaKeySelection="false"
            class="form-input center-items input-wrapper"
          ></TreeSelect>
        </div>
      </Transition>
    </div>
    <RoundedButton
      class="save-button"
      mediumwide
      largerFont
      text="Save"
      @click="stopRecorderAndSave"
      :loading="loading"
      :disabled="loading"
    />
    <!-- <div class="label">TAGS</div>
    <TreeSelect
      v-model="selectedTags"
      :options="nodes"
      selectionMode="multiple"
      placeholder="Select..."
      display="chip"
      :metaKeySelection="false"
      class="form-input"
    ></TreeSelect> -->
  </div>
</template>
<script>
import RoundedButton from "../components/RoundedButton";

import IconButton from "../components/IconButton.vue";
import ArrowIcon from "../assets/images/ArrowWhiteIcon.svg";
import CloseCircleIcon from "../assets/images/CloseCircle.svg";
import CheckmarkIcon from "../assets/images/Checkmark-black.svg";

export default {
  components: {
    RoundedButton,
    IconButton,
  },
  props: {
    recordingTitle: String,
    recordingDuration: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
  },
  watch: {
    title(newTitle) {
      this.$emit("updateTitle", newTitle);
    },
  },
  data() {
    return {
      ArrowIcon,
      CloseCircleIcon,
      CheckmarkIcon,
      toggleDetail: false,
      loading: false,
      title: null,
      selectedSubject: null,
      showClear: false,
      filteredSubjects: [],
      groupedSubjects: [
        {
          label: "Agriculture",
          items: [
            "Agriculture Business",
            "Agriculture Economics",
            "Agriculture Equipment",
            "Agriculture Management",
            "Agronomy",
            "Animal Husbandry",
            "Crop Production",
            "Fishery",
            "Food Science",
            "Forestry",
            "Horticulture",
            "Soil Science",
            "Tropical Agriculture",
            "Veterinary Science",
            "Water Science",
          ],
        },
        {
          label: "Architecture & Planning",
          items: [
            "Architecture",
            "Regional Planning",
            "Urban Planning",
            "Interior Design",
          ],
        },
        {
          label: "Arts & Humanities",
          items: [
            "Archaeology",
            "Classical Languages",
            "Comparative Literature",
            "English",
            "Geography",
            "History ",
            "Linguistics ",
            "Literature ",
            "Modern Languages",
            "Philosophy",
            "Politics and Governance",
            "Translation and Interpretation",
            "Writing ",
            "Women & Gender Studies",
          ],
        },
        {
          label: "Business and Management",
          items: [
            "Accounting and Finance",
            "Administration",
            "Commercial and Marketing",
            "Consulting and Analyzing",
            "Human Resources",
            "Management",
            "Tourism",
            "Business and Commerce",
            "Business Technology Management",
            "Economics",
            "Entrepreneurship and Strategy",
            "International Business",
            "Insurance",
            "Real Estate",
            "Secretarial Studies",
            "Labour and Industrial Relations",
          ],
        },
        {
          label: "Education",
          items: [
            "Educational Studies",
            "Special Education",
            "Teacher Training",
            "Early Childhood Education",
          ],
        },
        {
          label: "Engineering",
          items: [
            "Aeronautical and Aerospace Engineering",
            "Agricultural Engineering",
            "Automation and Control Engineering",
            "Bioengineering",
            "Biomedical Engineering",
            "Chemical Engineering",
            "Civil Engineering",
            "Computer Engineering",
            "Electrical and Electronic Engineering",
            "Energy Engineering",
            "Engineering Drawing & Design",
            "Engineering Management",
            "Environmental Engineering",
            "Industrial Engineering",
            "Marine Engineering",
            "Materials Engineering",
            "Measurement and Precision Engineering",
            "Mechanical Engineering",
            "Metallurgical Engineering",
            "Mining Engineering",
            "Nanotechnology",
            "Nuclear Engineering",
            "Petroleum and Gas Engineering",
            "Physical Engineering",
            "Production Engineering",
          ],
        },
        {
          label: "Health Sciences",
          items: [
            "Alternative Medicine",
            "Biomedicine",
            "Dentistry",
            "Health Administration",
            "Medical Auxiliaries",
            "Medical Technology",
            "Medicine",
            "Midwifery",
            "Nursing",
            "Optometry",
            "Pharmacy",
            "Public Health",
            "Rehabilitation and Therapy",
            "Surgery",
            "Health Research",
            "Physiotherapy / Massage",
            "Medical Science",
            "Treatment Techniques",
            "Addiction and Mental Health",
          ],
        },
        {
          label: "Home Economics",
          items: [
            "Child Care and Development",
            "Clothing and Sewing",
            "Consumer Studies",
            "House Arts and Environment",
            "Household Management",
            "Nutrition",
          ],
        },
        {
          label: "Information Sciences",
          items: [
            "Information Management",
            "Information Technology",
            "Library Science",
            "Mass Communication",
            "Museum Studies",
          ],
        },
        {
          label: "Law",
          items: [
            "Air and Space Law",
            "Canon Law",
            "Comparative Law",
            "Criminal Law",
            "European Union Law ",
            "History of Law",
            "Human Rights",
            "International Law",
            "Islamic Law",
            "Justice Administation",
            "Labour Law",
            "Maritime Law",
            "Notary Studies",
            "Private Law",
            "Public Law",
          ],
        },
        {
          label: "Mathematics & Computer Science",
          items: [
            "Actuarial Science",
            "Computer Science",
            "Mathmatics",
            "Statistics",
            "Calculus",
          ],
        },
        {
          label: "Natural Sciences",
          items: [
            "Astronomy and Space Science",
            "Biological and Life Sciences",
            "Chemistry ",
            "Earth Sciences",
            "Marine Science and Oceanography",
            "Meteorology",
            "Physics",
          ],
        },
        {
          label: "Religion",
          items: [
            "Comparative Religion",
            "Esoteric Practices",
            "History of Religion",
            "Holy Writings",
            "Missionary Studies",
            "Pastoral Studies",
            "Religious Education ",
            "Religious Studies",
            "Theology",
          ],
        },
        {
          label: "Service Trades (Hospitality)",
          items: [
            "Culinary Arts",
            "Hospitality and Tourism",
            "Cosmetology",
            "Retailing and Wholesaling",
            "Event Planning",
            "Food and Nutrition Management",
          ],
        },
        {
          label: "Social Sciences",
          items: [
            "Anthropology",
            "Behavioural Sciences",
            "Cognitive Sciences",
            "Communication Studies",
            "Cultural Studies",
            "Demography and Population",
            "Development Studies",
            "Economics",
            "Gender Studies",
            "Geography (Human)",
            "Heritage Preservation",
            "International Studies",
            "Psychology",
            "Regional Studies",
            "Rural Studies",
            "Sociology",
            "Urban Studies",
          ],
        },
        {
          label: "Technology",
          items: [
            "Building Technologies",
            "Crafts and Trades",
            "Food Technology",
            "Graphic Arts",
            "Heating and Refrigeration",
            "Instrument Making",
            "Laboratory Techniques",
            "Maintenance Technology",
            "Metal Techniques ",
            "Optical Technology",
            "Paper Technology",
            "Textile Technology",
            "Wood Technology",
          ],
        },
        {
          label: "Transport and Communications",
          items: [
            "Air Transport",
            "Marine Transport",
            "Postal Services",
            "Railway Transport",
            "Road Transport",
            "Telecommunications Services",
            "Transport Economics",
            "Transport Management ",
          ],
        },
        {
          label: "Welfare and Protective Services",
          items: [
            "Environmental Studies",
            "Leisure Studies",
            "Parks and Recreation",
            "Peace and Disarmament",
            "Protective Services",
            "Social Work",
            "Sports",
            "Vocational Counselling",
          ],
        },
      ],
      nodes: [
        {
          key: "0",
          label: "Context tags",
          children: [
            { key: "0-0", label: "Noisy Audio" },
            { key: "0-1", label: "Test Audio" },
            { key: "0-2", label: "Exam Prep" },
            { key: "0-3", label: "Group Discussion" },
            { key: "0-4", label: "Q&A Session" },
            { key: "0-5", label: "Lab Experiment" },
          ],
        },
        {
          key: "1",
          label: "Emotion Tags",
          children: [
            { key: "1-0", label: "😃 Happy" },
            { key: "1-1", label: "🤩 Excited" },
            { key: "1-2", label: "🔥 Motivated" },
            { key: "1-3", label: "✨ Inspired" },
            { key: "1-4", label: "😎 Confident" },
            { key: "1-5", label: "😊 Relaxed" },
          ],
        },
      ],
      selectedTags: null,
    };
  },
  methods: {
    addZero(timeValue) {
      return timeValue < 10 ? "0" + `${timeValue}` : `${timeValue}`;
    },
    search(event) {
      console.log("SEARCH");
      let query = event.query;
      let filteredSubjects = [];

      for (let subjectCategory of this.groupedSubjects) {
        let filteredItems = subjectCategory.items.filter((name) =>
          name.toLowerCase().includes(query.toLowerCase())
        );

        if (filteredItems && filteredItems.length) {
          filteredSubjects.push({
            ...subjectCategory,
            ...{ items: filteredItems },
          });
        }
      }

      this.filteredSubjects = filteredSubjects;
    },
    stopRecorderAndSave() {
      this.loading = true;
      this.$emit("stopRecorder");
    },
    clearText() {
      this.title = "";
    },

    filterKey(event) {
      let currentStr = event.target.value;
      let key = event.keyCode || event.charCode;
      // Ignore backspace (8) and delete (46)
      //        left (37) and right (39)
      if (
        currentStr.length > 50 &&
        key != 8 &&
        key != 46 &&
        key != 37 &&
        key != 39
      ) {
        event.preventDefault();
      }
    },

    delayRemove() {
      // TODO: come up with something else
      setTimeout(() => (this.showClear = false), 250);
    },
  },

  mounted() {
    this.groupedSubjects.sort((a, b) => {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    this.groupedSubjects.forEach((obj) => {
      obj.items.sort();
    });
    this.filteredSubjects = this.groupedSubjects;
    this.title = this.recordingTitle;
  },
};
</script>
<style lang="scss">
$row-width: 45rem;
.wrapper {
  max-width: 60rem;
  text-align: center;
  margin-top: 6.4rem;
  height: 100%;
  overflow: auto;
  padding-right: 1.2rem;

  @media only screen and (max-width: 900px) {
    width: 80vw;
  }

  .gone {
    display: none;
  }

  .form {
    background-color: map-get($colours, accent-purple-3);
    width: 100%;
    padding: 2.4rem 4rem 3.2rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: height 0.4s ease-out;

    .clear-icon {
      border-radius: 50%;
    }

    .form-title {
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      border-bottom: 2px solid rgba(25, 25, 25, 0.3);
      padding-bottom: 2rem;
      width: 100%;
      font-family: $font-secondary;
      font-weight: 500;

      .duration-label {
        margin-right: 4.8rem;
      }
    }

    .form-text {
      font-size: 1.4rem;
      color: map-get($colours, grey-3);
      text-align: left;
      font-family: $font-secondary;
      font-weight: 500;
      margin-top: 1.6rem;
    }

    .details-section {
      width: 100%;
    }
  }

  .title {
    font-size: 3rem;
    text-align: left;
    font-family: $font-accent;
    margin-bottom: 2.4rem;
  }

  .label {
    font-size: 1.4rem;
    color: map-get($colours, grey-1);
    font-family: $font-secondary;
    font-weight: 500;
    align-self: flex-start;
    text-align: left;
    margin-bottom: 0.8rem;
    margin-top: 1.6rem;
  }

  .details-button {
    font-weight: 600;
    background: transparent;
    display: flex;
    align-items: center;
    margin: 2.4rem 0 0.4rem;

    .details-button-icon {
      margin-right: 0.4rem;
    }
  }

  p {
    font-size: 1.6rem;
  }

  .input-wrapper {
    width: 100%;
  }

  .form-input,
  .p-autocomplete-input {
    height: 4.6rem;
    text-align: left;
    width: 100%;
    background-color: map-get($colours, accent-purple-2);
    color: #bababa;

    .p-placeholder {
      color: #bababa;
      font-size: 1.6rem;
      margin-top: 0.5rem;
    }
  }
  .form-input:hover {
    border: solid 1px #d1d1d1;
  }
  .form-input:active {
    border: solid 1px #d1d1d1;
  }
  .drop-down-button {
    background: none;
  }
  button {
    background-color: #6d5b79;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input::placeholder {
    color: #bababa;
  }

  .p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
    background: none;
    color: #bababa;
    &:enabled:hover {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0px;
      background: transparent;
      color: #fff;
    }
  }
  .p-autocomplete-dd .p-autocomplete-dropdown:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
    background: none;
    color: #fff;
  }
  .p-treeselect-items-wrapper {
    background-color: #2d2037;
  }
  .p-autocomplete-input {
    color: #bababa;
    border-radius: 0.6rem;
  }
  .p-autocomplete-dd-input {
    color: #bababa;
    background-color: transparent;
    border: none;
    &:enabled:focus {
      box-shadow: unset;
    }
  }
  .p-autocomplete-dd {
    color: #bababa;
    border-radius: 0.4rem;
  }
  .p-treeselect-trigger-icon {
    font-size: 1.4rem;
    margin-right: 3.4rem;
    color: #bababa;
  }
  .p-treeselect-trigger-icon:hover {
    color: #fff;
  }

  .p-treeselect-token-label {
    color: #fff;
  }

  .center-items {
    display: flex;
    align-items: center;
  }

  .arrow-up {
    transform: rotate(-90deg);
  }

  .arrow-down {
    transform: rotate(90deg);
  }

  .save-button {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-family: "Averta";
    font-weight: bold;
    color: black;
    border-radius: 0.8rem;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 2.4rem;
    margin-right: 0;
    display: flex;
  }
}
.p-autocomplete-panel {
  background-color: #3b2c47;

  .p-autocomplete-item {
    color: #fff;
  }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  color: #fff;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #fff;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  background-color: #3b2c47;
  color: #bababa;
  font-size: 1.4rem;

  // .p-autocomplete-items {
  //   font-family: $font-secondary;
  //   letter-spacing: 0.015rem;
  //   background-color: #3b2c47;
  //   .p-autocomplete-item {
  //     color: #fff;

  //     &:hover {
  //       color: #fff;
  //     }
  //     &.p-highlight {
  //       color: #bababa;
  //     }
  //   }
  //   .p-autocomplete-item-group {
  //     background-color: #3b2c47;
  //     color: #bababa;
  //     font-size: 1.4rem;
  //   }
  // }

  // .p-autocomplete-empty-message {
  //   margin-left: 1rem;
  //   color: #bababa;
  //   font-size: 1.4rem;
  //   line-height: 2.4rem;
  // }
}
</style>
